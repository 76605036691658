import { useEffect, useState } from 'react'
import { supabase } from 'api/axios.instance'
import InfoTable from 'components/AntD/Table'
import Title from 'components/Ui/Title'
import styles from 'assets/scss/detail.module.scss'
import Label from 'components/Ui/Label'
import { Col, Row, Space } from 'antd'
import Value from '../../Ui/Value'

const TutorExperience = ({ matchingList }) => {
    const [programData, setProgramData] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            if (matchingList && matchingList.length > 0) {
                try {
                    /**
                     * 프로그램 정보 호출
                     */
                    const programInfoPromises = matchingList.map(async ({ program_id, ...rest }) => {
                        const { data, error } = await supabase
                            .from('program_info')
                            .select(
                                'program_name, organization_name, address_sido, address_bname, address_sigungu, start_date, end_date',
                            )
                            .eq('program_id', program_id)
                            .single()

                        if (error) {
                            console.error(`Error fetching program_info for program_id ${program_id}:`, error)
                            return { ...rest, key: program_id }
                        }

                        return {
                            key: program_id,
                            program_id,
                            program_name: data.program_name,
                            organization_name: data.organization_name,
                            organization_address: `${data.address_sido} ${data.address_sigungu} ${data.address_bname}`,
                            start_date: data.start_date, // 날짜 정렬을 위해 포함
                            ...rest,
                        }
                    })

                    const updatedProgramData = await Promise.all(programInfoPromises)

                    updatedProgramData.sort((a, b) => {
                        if (a.tutor_type === '주강사' && b.tutor_type !== '주강사') return -1
                        if (a.tutor_type !== '주강사' && b.tutor_type === '주강사') return 1

                        return new Date(a.start_date) - new Date(b.start_date)
                    })

                    setProgramData(updatedProgramData)
                } catch (error) {
                    console.error('Error fetching program_info:', error)
                }
            }
        }

        fetchData()
    }, [matchingList])

    const mainTutorCount = matchingList.filter(({ tutor_type }) => tutor_type === '주강사').length
    const mainTutorHours = matchingList
        .filter(({ tutor_type }) => tutor_type === '주강사')
        .reduce((acc, { class_hours }) => acc + class_hours, 0)
    const subTutorCount = matchingList.filter(({ tutor_type }) => tutor_type === '보조강사').length
    const subTutorHours = matchingList
        .filter(({ tutor_type }) => tutor_type === '보조강사')
        .reduce((acc, { class_hours }) => acc + class_hours, 0)
    const totalHours = matchingList.reduce((acc, { class_hours }) => acc + class_hours, 0)
    const totalCount = matchingList.length

    return (
        <div>
            <Title title="경력이력" />
            {matchingList && matchingList.length > 0 ? (
                <Space direction="vertical" size="large">
                    <InfoTable data={programData} className={`${styles.mb}`} />
                    <div className={`${styles.border_style} ${styles.box_md} `}>
                        <Row className={styles.row_item}>
                            <Col span={12}>
                                <Row>
                                    <Col span={10}>
                                        <Label label="주강사" />
                                    </Col>
                                    <Col className={styles.padding_y_medium}>
                                        <Value value={mainTutorCount} addonAfter="회" />
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={12}>
                                <Row>
                                    <Col span={10}>
                                        <Label label=" 수업시수" />
                                    </Col>
                                    <Col className={styles.padding_y_medium}>
                                        <Value value={mainTutorHours} addonAfter="시간" />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className={styles.row_item}>
                            <Col span={12}>
                                <Row>
                                    <Col span={10}>
                                        <Label label="보조강사" />
                                    </Col>
                                    <Col className={styles.padding_y_medium}>
                                        <Value value={subTutorCount} addonAfter="회" />
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={12}>
                                <Row>
                                    <Col span={10}>
                                        <Label label=" 수업시수" />
                                    </Col>
                                    <Col className={styles.padding_y_medium}>
                                        <Value value={subTutorHours} addonAfter="시간" />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className={styles.row_item}>
                            <Col span={12}>
                                <Row>
                                    <Col span={10}>
                                        <Label label="총 회차" />
                                    </Col>
                                    <Col className={styles.padding_y_medium}>
                                        <Value value={totalCount} addonAfter="회" />
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={12}>
                                <Row>
                                    <Col span={10}>
                                        <Label label="총 수업시수" />
                                    </Col>
                                    <Col className={styles.padding_y_medium}>
                                        <Value key="" value={totalHours} addonAfter="시간" />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </Space>
            ) : (
                <div className={` ${styles.border_style}`}>
                    <div className={styles.not_found_page_wrap}>
                        <div className={styles.not_found_page_top}>
                            <span className={styles.not_found_page_icon} />
                            <div className={styles.not_found_page_title}>
                                <span className="sub_title1">매칭된 교육프로그램이 없습니다</span>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default TutorExperience
