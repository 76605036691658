import { useState } from 'react'
import dayjs from 'dayjs'

import { Col, Row, Form } from 'antd'

import { useDispatch } from 'react-redux'
import { setProgramField } from 'store/Slices/programs'

import Label from 'components/Ui/Label'
import SelectUi from 'components/Ui/Select/SelectUi'
import Title from 'components/Ui/Title'
import Value from 'components/Ui/Value'
import DatePickerInput from 'components/AntD/DatePicker'
import TextField from 'components/AntD/TextField'

import styles from 'assets/scss/detail.module.scss'

const OperationInfo = ({ program_operation_info, editOrRegisterPage }) => {
    const [localOperationInfo, setLocalOperationInfo] = useState(program_operation_info || {})
    const dispatch = useDispatch()

    const handleStateChange = field => value => {
        setLocalOperationInfo(prevState => ({
            ...prevState,
            [field]: value,
        }))
    }
    const handleChange = (key, value) => {
        dispatch(
            setProgramField({
                field: 'programOperationInfo',
                data: { ...localOperationInfo, [key]: value },
            }),
        )
    }

    return (
        <div>
            <Title title="프로그램 운영정보" />
            <div className={`${styles.box_md} ${styles.border_style}`}>
                {/* 교육시간 */}
                <Row className={styles.row_item}>
                    <Col span={5}>
                        <Label label="교육시간 " required={editOrRegisterPage} />
                    </Col>
                    {editOrRegisterPage ? (
                        <Col span={19} className={`${styles.padding_top} ${styles.padding_right}`}>
                            <Form.Item
                                initialValue={localOperationInfo?.classHours}
                                name="classHours"
                                noStyle
                                rules={[{ required: true }]}
                            >
                                <TextField
                                    onChange={value => handleChange('classHours', value)}
                                    name="classHours"
                                    val={localOperationInfo?.classHours}
                                />
                            </Form.Item>
                        </Col>
                    ) : (
                        <Col span={19} className={`${styles.padding_y_medium}`}>
                            <Value value={program_operation_info?.classHours} />
                        </Col>
                    )}
                </Row>
                {/* 구분 (교육시간에 따른 장기/단기 노출) */}
                {!editOrRegisterPage && (
                    <Row className={styles.row_item}>
                        <Col span={5}>
                            <Label label="구분" />
                        </Col>
                        <Col span={19}>
                            <Col span={19}>
                                <SelectUi
                                    disabled
                                    size="md"
                                    options={['단기', '장기']}
                                    value={program_operation_info?.classHours < 8 ? '단기' : '장기'}
                                />
                            </Col>
                        </Col>
                    </Row>
                )}
                {/* 신청인원(명), 수료인원(명) */}
                <Row className={styles.row_item}>
                    <Col span={12}>
                        <Row>
                            <Col span={10}>
                                <Label label="신청인원 (명)" required={editOrRegisterPage} />
                            </Col>
                            {editOrRegisterPage ? (
                                <Col span={14} className={`${styles.padding_top} ${styles.padding_right}`}>
                                    <Form.Item
                                        initialValue={localOperationInfo?.numberOfApplicants}
                                        name="numberOfApplicants"
                                        noStyle
                                        rules={[{ required: true }]}
                                    >
                                        <TextField
                                            onChange={value => handleChange('numberOfApplicants', value)}
                                            name="numberOfApplicants"
                                            val={localOperationInfo?.numberOfApplicants}
                                        />
                                    </Form.Item>
                                </Col>
                            ) : (
                                <Col span={10} className={`${styles.padding_y_medium}`}>
                                    <Value value={program_operation_info?.numberOfApplicants} />
                                </Col>
                            )}
                        </Row>
                    </Col>
                    <Col span={12}>
                        <Row>
                            <Col span={10}>
                                <Label label="수료인원 (명)" required={editOrRegisterPage} />
                            </Col>
                            {editOrRegisterPage ? (
                                <Col span={14} className={`${styles.padding_top} ${styles.padding_right}`}>
                                    <Form.Item
                                        initialValue={localOperationInfo?.numberOfGraduates}
                                        name="numberOfGraduates"
                                        noStyle
                                        rules={[{ required: true }]}
                                    >
                                        <TextField
                                            onChange={value => handleChange('numberOfGraduates', value)}
                                            name="numberOfGraduates"
                                            val={localOperationInfo?.numberOfGraduates}
                                        />
                                    </Form.Item>
                                </Col>
                            ) : (
                                <Col span={10} className={`${styles.padding_y_medium}`}>
                                    <Value value={program_operation_info?.numberOfGraduates} />
                                </Col>
                            )}
                        </Row>
                    </Col>
                </Row>
                {/* 운영상태 */}
                <Row className={styles.row_item}>
                    <Col span={5}>
                        <Label label="운영상태 " required={editOrRegisterPage} />
                    </Col>
                    <Col span={19}>
                        {editOrRegisterPage ? (
                            <Form.Item
                                initialValue={localOperationInfo?.status}
                                name="status"
                                noStyle
                                rules={[{ required: true }]}
                            >
                                <SelectUi
                                    onChange={handleStateChange('status')}
                                    options={['예정', '진행', '완료', '취소']}
                                    value={localOperationInfo?.status}
                                />
                            </Form.Item>
                        ) : (
                            <SelectUi
                                disabled
                                options={['예정', '진행', '완료', '취소']}
                                value={program_operation_info?.status}
                            />
                        )}
                    </Col>
                </Row>
                {/* 교육시작일, 교육종료일 */}
                <Row className={styles.row_item}>
                    <Col span={12}>
                        <Row>
                            <Col span={10}>
                                <Label label="교육시작일" required={editOrRegisterPage} />
                            </Col>
                            {editOrRegisterPage ? (
                                <Col span={14}>
                                    <Form.Item
                                        initialValue={localOperationInfo?.startDate || dayjs()}
                                        name="startDate"
                                        noStyle
                                        rules={[{ required: true }]}
                                    >
                                        <DatePickerInput
                                            name="startDate"
                                            defaultValue={localOperationInfo?.startDate || dayjs()}
                                            onChange={handleStateChange('startDate')}
                                        />
                                    </Form.Item>
                                </Col>
                            ) : (
                                <Col className={`${styles.padding_y_medium}`}>
                                    <Value type="date" value={program_operation_info?.startDate} />
                                </Col>
                            )}
                        </Row>
                    </Col>
                    <Col span={12}>
                        <Row>
                            <Col span={10}>
                                <Label label="교육종료일" required={editOrRegisterPage} />
                            </Col>
                            {editOrRegisterPage ? (
                                <Col span={14}>
                                    <Form.Item
                                        initialValue={localOperationInfo?.endDate || dayjs()}
                                        name="endDate"
                                        noStyle
                                        rules={[{ required: true }]}
                                    >
                                        <DatePickerInput
                                            name="endDate"
                                            defaultValue={localOperationInfo?.endDate || dayjs()}
                                            onChange={handleStateChange('endDate')}
                                        />
                                    </Form.Item>
                                </Col>
                            ) : (
                                <Col className={`${styles.padding_y_medium}`}>
                                    <Value type="date" value={program_operation_info?.endDate} />
                                </Col>
                            )}
                        </Row>
                    </Col>
                </Row>
                {/* 등록연도, 교육 횟수 */}
                <Row className={styles.row_item}>
                    <Col span={12}>
                        <Row>
                            <Col span={10}>
                                <Label label="등록연도" required={editOrRegisterPage} />
                            </Col>
                            {editOrRegisterPage ? (
                                <Col span={14} className={`${styles.padding_top} ${styles.padding_right}`}>
                                    <Form.Item
                                        rules={[{ required: true, message: '' }]}
                                        initialValue={localOperationInfo?.registrationYear}
                                        name="registrationYear"
                                    >
                                        <TextField
                                            onChange={value => handleChange('registrationYear', value)}
                                            name="registrationYear"
                                            val={localOperationInfo?.registrationYear}
                                        />
                                    </Form.Item>
                                </Col>
                            ) : (
                                <Col className={`${styles.padding_y_medium}`}>
                                    <Value value={program_operation_info?.registrationYear} />
                                </Col>
                            )}
                        </Row>
                    </Col>
                    <Col span={12}>
                        <Row className={styles.row_item}>
                            <Col span={10}>
                                <Label label="교육 횟수" />
                            </Col>
                            {editOrRegisterPage ? (
                                <Col span={14} className={`${styles.padding_top} ${styles.padding_right}`}>
                                    <Form.Item
                                        initialValue={localOperationInfo?.numberOfSession}
                                        name="numberOfSession"
                                    >
                                        <TextField
                                            onChange={value => handleChange('numberOfSession', value)}
                                            name="numberOfSession"
                                            val={localOperationInfo?.numberOfSession}
                                        />
                                    </Form.Item>
                                </Col>
                            ) : (
                                <Col className={`${styles.padding_y_medium}`}>
                                    <Value value={program_operation_info?.numberOfSession} />
                                </Col>
                            )}
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    )
}
export default OperationInfo
