import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { supabase } from 'api/axios.instance'
import { convertKeysToCamelCase } from 'utils/utilCommon'

export const loginUserAsync = createAsyncThunk('user/login', async (userInfo, { rejectWithValue }) => {
    const { id, password } = userInfo
    try {
        const { data } = await supabase.auth.signInWithPassword({
            email: `${id}@swedulink.com`,
            password,
        })

        if (data.session !== null) {
            return data // 성공 시 데이터 반환
        }

        // TODO: 세션이 null일 때 처리 추가 필요
        return rejectWithValue(new Error(data.error?.message || 'Unknown error'))
    } catch (error) {
        return rejectWithValue(error)
    }
})

export const getUserAsync = createAsyncThunk('user/get', async (userId, { rejectWithValue }) => {
    try {
        const { data } = await supabase.from('user_info').select().eq('user_id', userId).select()

        if (data) {
            return data[0] // 성공 시 데이터 반환
        }
        return rejectWithValue(new Error(data.error.message))
    } catch (error) {
        return rejectWithValue(error)
    }
})

export const logoutUserAsync = createAsyncThunk('user/logout', async () => {
    return true
})
const initialState = {
    isFetching: false,
    isLogin: false, // 임시 로그인 여부 변경
    userInfo: {
        regionId: '',
        isMaster: null,
        isSubMaster: null, // 계정관리 안되는 어드민 계정 (Nipa, 과기부)
        userId: '',
    },
    accessInfo: null,
    error: null,
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            // loginUserAsync
            .addCase(loginUserAsync.pending, state => {
                state.isFetching = true
            })
            .addCase(loginUserAsync.fulfilled, (state, { payload }) => {
                const camelCasePayload = convertKeysToCamelCase(payload)
                state.isFetching = false
                state.isLogin = true
                state.accessInfo = camelCasePayload
            })
            .addCase(loginUserAsync.rejected, (state, { error }) => {
                state.userInfo = { regionId: '', isMaster: null, userId: '' }
                state.isFetching = false
                state.isLogin = false
                state.error = error.message
                state.accessInfo = null
            })
            // getUserAsync
            .addCase(getUserAsync.pending, state => {
                state.isFetching = true
            })
            .addCase(getUserAsync.fulfilled, (state, { payload }) => {
                const camelCasePayload = convertKeysToCamelCase(payload)
                state.isFetching = false
                state.isLogin = true
                state.userInfo = camelCasePayload
            })
            .addCase(getUserAsync.rejected, (state, { error }) => {
                state.isFetching = false
                state.error = error.message
                state.userInfo = {
                    regionId: '',
                    isMaster: null,
                    userId: '',
                }
                state.accessInfo = null
                state.isLogin = false
            })
            // logoutUserAsync
            .addCase(logoutUserAsync.fulfilled, state => {
                state.userInfo = { regionId: '', isMaster: null, userId: '' }
                state.isFetching = false
                state.isLogin = false
                state.error = null
                state.accessInfo = null
                window.location.href = '/login'
            })
    },
})

export const getAllUserInfo = state => state.user
export const getUser = state => state.user.userInfo
export const getIsFetching = state => state.user.isFetching
export const getError = state => state.user.error
export const getIsLoggedIn = state => state.user.isLogin

// export default userSlice.reducer
export default userSlice
