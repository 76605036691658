import ProjectExpenseSection from 'pages/Report/Detail/ProjectExpenseSection'
import dayjs from 'dayjs'
import PlanTable from './PlanTable'
import PlanTableDetail from './PlanTableDetail'
import PlanTableNext from './PlanTableNext'
//import styles from './report.module.scss'

const TableSection = ({ currentMonth, report, reportTotal, executionData, createMonth }) => {
    const { monthlyBudgetExecution, planAndPerformanceInfo } = report || {}
    const currentMM = currentMonth || ''
    const selectedYear = dayjs(createMonth).format('YYYY') // 선택한 년도
    const selectedMonth = dayjs(createMonth).format('M')

    const nextMonth = String((Number(currentMM) % 12) + 1).padStart(2, '0')
    const { monthlyPlan, nextMonthPlane, monthlyPerformance } = planAndPerformanceInfo || {}

    const totalBudget = {
        title: '총 사업비',
        rows: [
            {
                name: 'totalBudgetGovernmentFunds',
                label: '현금',
                value: reportTotal?.government_funds || 0,
                placeholder: '현금을 입력해주세요.',
            },
            {
                name: 'totalBudgetLocalCashFunds',
                label: '지방비 현금',
                value: reportTotal?.local_cash_funds || 0,
                placeholder: '지방비 현금을 입력해주세요.',
            },
            {
                name: 'totalBudgetLocalInKindFunds',
                label: '지방비 현물',
                value: reportTotal?.localin_kind_funds || 0,
                placeholder: '지방비 현물을 입력해주세요.',
            },
            {
                name: 'totalBudgetTotal',
                label: 'Total',
                value: reportTotal?.government_funds || 0,
            },
        ],
    }

    const monthlyBudget = {
        title: `${currentMM}월 예산집행`,
        rows: [
            {
                name: 'monthlyBudgetGovernmentFunds',
                label: '현금',
                value: monthlyBudgetExecution?.governmentFunds,
                placeholder: '현금을 입력해주세요.',
            },
            {
                name: 'monthlyBudgetLocalInKindFunds',
                label: '현물',
                value: monthlyBudgetExecution?.localCashFunds,
                placeholder: '현물을 입력해주세요.',
            },
            {
                name: 'monthlyBudgetTotal',
                label: 'Total',
                value: monthlyBudgetExecution?.total,
            },
        ],
    }

    const accumulated = {
        title: '누적 집행 금액',
        rows: [
            {
                name: 'accumulatedCash',
                label: '현금',
                value: executionData?.executionGovernmentFunds || 0,
                placeholder: '현금을 입력해주세요.',
            },
            {
                name: 'accumulatedInkind',
                label: '현물',
                value: executionData?.executionLocalCashFunds || 0,
                placeholder: '현물을 입력해주세요.',
            },
            {
                name: 'accumulatedExpendTotal',
                label: 'Total',
                value: executionData?.executionTotal || 0,
            },
        ],
    }

    return (
        <>
            <ProjectExpenseSection
                reportTotal={reportTotal}
                totalBudget={totalBudget}
                monthlyBudget={monthlyBudget}
                monthlyBudgetExecution={monthlyBudgetExecution}
                accumulated={accumulated}
                executionData={executionData}
            />
            {/*당월 계획*/}
            <PlanTable title={currentMM} info={monthlyPlan} name="monthlyPlan" plan />
            {/* 당월 실적*/}
            <PlanTableDetail
                title={currentMM}
                info={monthlyPerformance}
                name="monthlyPerformance"
                month={selectedMonth}
                year={selectedYear}
                top
            />
            {/* 익월 계획 */}
            <PlanTableNext title={nextMonth} info={nextMonthPlane} name="nextMonthPlan" next top plan />
        </>
    )
}

export default TableSection
