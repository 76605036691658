import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getUser } from 'store/Slices/user'

import { deleteTutorById } from 'api/tutors/tutors.api'

import { Col, Dropdown, Row, Tooltip } from 'antd'

import {
    AdvancedTutorChipsIcon,
    ContractBadgeIcon,
    ExpireBadgeIcon,
    ListItemMoreIcon,
    NormalTutorChipsIcon,
    OnWorkingIcon,
} from 'assets/Icons'
import { colorTheme } from 'assets/colorTheme'

import { getAgeFromJumin, getGenderFromResidentNumber } from 'utils/utilCommon'

import DeleteModalContent from 'components/AntD/Dialog/deleteModalContent'
import InfoDialog from 'components/AntD/Dialog'
import Chips from 'components/AntD/Chips'

import styles from './gridListItem.module.scss'

const GridListItem = ({ item, setLoading, fetchList }) => {
    const location = useLocation()
    const { isMaster } = useSelector(getUser)

    const { gray, yellow, blue, green, red } = colorTheme

    const {
        tutorSortation,
        tutorContractInfo,
        tutorQualifications,
        tutorId,
        tutorName,
        residentNum,
        gender,
        educationLevel,
    } = item
    // const { tutor_name, resident_num, gender, education_level } = tutor_info

    const { employmentStatus, employmentType } = tutorContractInfo
    const { privateCertification } = tutorQualifications
    const { isAdvancedInstructor } = tutorSortation

    const [isModalOpen, setIsModalOpen] = useState(false)

    const handleDeleteProgram = e => {
        e.preventDefault()
        e.stopPropagation()
        setIsModalOpen(true)
    }

    const handleConfirmDelete = async e => {
        const { search } = location
        const searchPath = search.split('?')[1] || ''

        e.preventDefault()
        e.stopPropagation()

        setLoading(true)
        const deleteTutor = async () => {
            try {
                const response = await deleteTutorById(tutorId)
                console.log('response : ', response)
                return false
            } catch (error) {
                console.error('프로그램 목록을 불러오는 동안 오류가 발생했습니다.', error)
                return null
            }
        }
        await deleteTutor()
        await fetchList(searchPath)
        setIsModalOpen(false)
        setLoading(false)
    }

    const handleBubbling = e => {
        e.stopPropagation() // 이벤트 버블링 방지
    }

    const handleDetailClick = e => {
        e.preventDefault()
        e.stopPropagation()

        window.open(`/tutors/${tutorId}`, '_blank', `fullscreen=yes`)
    }

    const items = [
        {
            label: (
                <Tooltip title="새창으로 열기" disableInteractive>
                    <button type="button" onClick={e => handleDetailClick(e)}>
                        <span className={styles.detail_icon} />
                    </button>
                </Tooltip>
            ),
            key: '1',
        },
        {
            label: (
                <Tooltip title="수정">
                    <Link to={`/tutors/edit/${tutorId}`} onClick={e => handleBubbling(e)}>
                        <span className={styles.edit_icon} />
                    </Link>
                </Tooltip>
            ),
            key: '2',
        },
        {
            label: (
                <Tooltip title="삭제">
                    <button type="button" className="button-reset-type" onClick={e => handleDeleteProgram(e)}>
                        <span className={styles.delete_icon} />
                    </button>
                </Tooltip>
            ),
            key: '3',
        },
    ]

    return (
        <>
            <div className={styles.badge}>
                {employmentStatus === '대기' && (
                    <Chips color={yellow['800']} icon={<ContractBadgeIcon />} tooltip={employmentStatus} />
                )}
                {employmentStatus === '재직중' && (
                    <Chips color={blue['600']} icon={<OnWorkingIcon />} tooltip={employmentStatus} />
                )}
                {employmentStatus === '계약만료' && (
                    <Chips color={gray['500']} icon={<ExpireBadgeIcon />} tooltip={employmentStatus} />
                )}
                <Chips
                    color={isAdvancedInstructor === '고급강사' ? green['400'] : red['700']}
                    icon={isAdvancedInstructor === '고급강사' ? <AdvancedTutorChipsIcon /> : <NormalTutorChipsIcon />}
                    tooltip={isAdvancedInstructor === '고급강사' ? '고급강사' : '일반강사'}
                />
                {!isMaster && (
                    <div className={styles.more}>
                        <Dropdown
                            menu={{
                                items,
                            }}
                        >
                            <button type="button" className="button-reset-type">
                                <ListItemMoreIcon />
                            </button>
                        </Dropdown>
                    </div>
                )}
            </div>
            <div className={styles.title_wrap}>
                <div className={styles.title}>
                    <span className="sub_title1">
                        {tutorName}
                        <span className={styles.subtitle}>
                            ({`${gender || getGenderFromResidentNumber(residentNum)} / ${getAgeFromJumin(residentNum)}`}
                            )
                        </span>
                    </span>
                </div>
            </div>
            <ul className={styles.item_wrap}>
                <Row>
                    <Col span={12} order={1}>
                        <ul>
                            <li className={styles.item}>
                                <span className={`body2 ${styles.subject}`}>고용형태</span>
                                <span className={`body2 ${styles.content}`}>{employmentType}</span>
                            </li>
                        </ul>
                    </Col>
                    <Col span={12} order={2}>
                        <ul>
                            <li className={styles.item}>
                                <span className={`body2 ${styles.subject}`}>최종학력</span>
                                <span className={`body2 ${styles.content}`}>{educationLevel}</span>
                            </li>
                        </ul>
                    </Col>
                    <Col span={12} order={2}>
                        <ul>
                            <li className={styles.item}>
                                <span className={`body2 ${styles.subject}`}>고급강사 여부</span>
                                <span className={`body2 ${styles.content}`}>{isAdvancedInstructor}</span>
                            </li>
                        </ul>
                    </Col>
                    <Col span={12} order={3}>
                        <ul>
                            <li className={styles.item}>
                                <span className={`body2 ${styles.subject}`}>SW 미래채움 민간자격 취득</span>
                                <span className={`body2 ${styles.content}`}>{privateCertification}</span>
                            </li>
                        </ul>
                    </Col>
                </Row>
            </ul>
            <InfoDialog
                content={<DeleteModalContent />}
                centered
                modalOpen={isModalOpen}
                setModalOpen={setIsModalOpen}
                closable={false}
                handleConfirm={handleConfirmDelete}
                isCancel
            />
        </>
    )
}

export default GridListItem
