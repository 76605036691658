import { useState } from 'react'
import { Input, ConfigProvider } from 'antd'
import TextArea from 'antd/es/input/TextArea'

import styles from './textfield.module.scss'

/**
 *
 * @param size
 * @param onChange
 * @param onClear
 * @param placeholder
 * @param disabled
 * @param val
 * @returns {JSX.Element}
 * @constructor
 */
const TextField = ({
    size,
    onChange,
    onBlur,
    name,
    className,
    placeholder = '',
    disabled,
    val,
    errorMessage,
    textArea,
    rows,
    resize,
    password,
}) => {
    const [inputValue, setInputValue] = useState(val || '')

    const getSize = () => {
        switch (size) {
            case '100':
                return styles.width_100
            case 'xs':
                return styles.width_xs
            case 'sm':
                return styles.width_sm
            case 'md':
                return styles.width_md
            case 'lg':
                return styles.width_lg
            case 'xl':
                return styles.width_xl
            default:
                return ''
        }
    }

    const handleChange = event => {
        const { value } = event.target
        setInputValue(value)
        if (onChange) onChange(value)
    }

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorText: '#7A7A7A',
                },
            }}
        >
            {!textArea ? (
                <Input
                    name={name}
                    disabled={disabled}
                    className={`${styles.ant_input} ${getSize()} ${className || ''}`}
                    value={inputValue}
                    onChange={handleChange}
                    onBlur={onBlur}
                    placeholder={placeholder}
                    type={password ? 'password' : 'text'}
                />
            ) : (
                <TextArea
                    name={name}
                    rows={rows}
                    disabled={disabled}
                    className={className || ''}
                    placeholder={placeholder}
                    style={{ resize: !resize ? 'none' : '' }}
                />
            )}
            {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
        </ConfigProvider>
    )
}

export default TextField
