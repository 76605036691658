import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Form } from 'antd'

import { findDuplicatedTutorName } from 'api/tutors/tutors.api'

import { useSelector } from 'react-redux'
import { getAllUserInfo } from 'store/Slices/user'

import { MailInfoIcon, UsrIcon, TelPhoneIcon, AddressIcon } from 'assets/Icons'
import TextField from 'components/AntD/TextField'
import {
    getAgeFromJumin,
    getGenderFromResidentNumber,
    hideResidentNumber,
    validateEmail,
    validateJuminNumber,
    validatePhoneNumber,
} from 'utils/utilCommon'

import style from 'assets/scss/detail.module.scss'

const TutorPersonalInfo = ({ tutor, isEditOrRegisterPage, newRegistrationYear }) => {
    const { pathname } = useLocation()
    const paths = pathname.split('/').filter(path => path !== '')
    const { userInfo } = useSelector(getAllUserInfo)
    const [localPersonalInfo] = useState(tutor)
    const newRegistrationYearNumber = Number(newRegistrationYear)
    const address = `${tutor.sido} ${tutor.sigungu} ${tutor.bname}`

    /**
     * 강사명 validate
     * @param _
     * @param value
     * @returns {Promise<void>}
     */
    const validateName = async (_, value) => {
        if (value && newRegistrationYearNumber) {
            const res = await findDuplicatedTutorName(value, userInfo.regionId, newRegistrationYearNumber)
            return res.data && res.data.length > 0 ? Promise.reject(new Error('대박')) : Promise.resolve()
        }
        return Promise.resolve()
    }

    return (
        <div className={style.profile}>
            {!isEditOrRegisterPage ? (
                <>
                    <div className={style.profile_align}>
                        <UsrIcon />
                        <span>
                            {hideResidentNumber(tutor.residentNum)} /
                            {tutor.gender || getGenderFromResidentNumber(tutor.residentNum)} /
                            {getAgeFromJumin(tutor.residentNum)}
                        </span>
                    </div>
                    <div className={style.profile_align}>
                        <TelPhoneIcon />
                        <span>{tutor.contact ?? ''}</span>
                    </div>
                    <div className={style.profile_align}>
                        <MailInfoIcon />
                        <span>{tutor.email ?? ''}</span>
                    </div>
                    <div className={style.profile_align}>
                        <AddressIcon />
                        <span>{address}</span>
                    </div>
                </>
            ) : (
                <>
                    <div className={`${style.profile_edit} ${paths[1] === 'register' ? style.start : ''}`}>
                        <UsrIcon />
                        {paths[1] === 'edit' && (
                            <span>
                                {localPersonalInfo.residentNum} /{' '}
                                {localPersonalInfo.gender || getGenderFromResidentNumber(localPersonalInfo.residentNum)}{' '}
                                / {getAgeFromJumin(localPersonalInfo?.residentNum)}
                            </span>
                        )}
                        {paths[1] === 'register' && (
                            <div className={style.user}>
                                <Form.Item
                                    rules={[
                                        { required: true, message: '강사명을 입력해주세요.' },
                                        { validator: validateName, message: '동일한 강사명이 존재합니다.' },
                                    ]}
                                    validateDebounce={500}
                                    name="tutorName"
                                >
                                    <TextField name="name" placeholder="홍길동" size="md" />
                                </Form.Item>
                                <Form.Item
                                    rules={[
                                        {
                                            validator: validateJuminNumber,
                                        },
                                    ]}
                                    name="residentNum"
                                >
                                    <TextField name="residentNum" placeholder="900000-1000000" size="md" />
                                </Form.Item>
                                {/*<Form.Item noStyle rules={[{ required: true }]} name="contact">*/}
                                {/*    <TextField name="gender" placeholder="성별" size="md" />*/}
                                {/*</Form.Item>*/}
                            </div>
                        )}
                    </div>
                    <div className={style.profile_edit}>
                        <TelPhoneIcon />
                        <Form.Item
                            rules={[{ validator: validatePhoneNumber }]}
                            name="contact"
                            initialValue={localPersonalInfo.contact}
                        >
                            <TextField
                                name="contact"
                                placeholder="010-1234-1234"
                                val={localPersonalInfo.contact}
                                size="md"
                            />
                        </Form.Item>
                    </div>
                    <div className={style.profile_edit}>
                        <MailInfoIcon />
                        <Form.Item
                            rules={[{ required: true, validator: validateEmail }]}
                            name="email"
                            initialValue={localPersonalInfo.email}
                        >
                            <TextField
                                name="email"
                                placeholder="exam@exam.com"
                                val={localPersonalInfo.email}
                                size="md"
                            />
                        </Form.Item>
                    </div>
                    <div className={style.profile_edit} style={{ alignItems: 'start' }}>
                        <div>
                            <AddressIcon />
                        </div>
                        <div className={style.address_form}>
                            <Form.Item
                                noStyle
                                rules={[{ required: true, message: '' }]}
                                name="sido"
                                initialValue={localPersonalInfo.sido}
                            >
                                <TextField
                                    name="sido"
                                    val={localPersonalInfo.sido}
                                    size="md"
                                    placeholder="주소(시/도)"
                                />
                            </Form.Item>
                            <Form.Item
                                noStyle
                                rules={[{ required: true, message: '' }]}
                                name="sigungu"
                                initialValue={localPersonalInfo.sigungu}
                            >
                                <TextField
                                    name="sigungu"
                                    val={localPersonalInfo.sigungu}
                                    size="md"
                                    placeholder="주소(시/군/구)"
                                />
                            </Form.Item>
                            <Form.Item
                                noStyle
                                rules={[{ required: true, message: '' }]}
                                name="bname"
                                initialValue={localPersonalInfo.bname}
                            >
                                <TextField
                                    name="bname"
                                    val={localPersonalInfo.bname}
                                    size="md"
                                    placeholder="주소(읍/면/동)"
                                />
                            </Form.Item>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

export default TutorPersonalInfo
