import { useState } from 'react'
import { useLocation } from 'react-router-dom'

import { useSelector } from 'react-redux'
import { getAllUserInfo } from 'store/Slices/user'

import dayjs from 'dayjs'
import ProgramFilter from './ProgramFilter'
import ExtraBtn from './ExtraBtn'
import ExpandProgram from './ExpandProgram'
import SurveyFilter from './SurveyFilter'

import TutorFilter from './TutorFilter'
import ExpandTutors from './ExpandTutors'

import StatisticsFilter from './StatisticsFilter'
import LocalStatisticsFilter from './LocalStatisticsFilter'
import InventoryFilter from './InventoryFilter'
import RentalFilter from './RentalFilter'
import ReportFilter from './ReportFilter'

import styles from '../filter.module.scss'

const startDate = 'startDate'
const endDate = 'endDate'

const FilterArea = ({
    updateQueryStringAndNavigate,
    handleResetQuerystring,
    startDateRange,
    setStartDateRange,
    endDateRange,
    setEndDateRange,
    isActiveLastMonth,
    setIsActiveLastMonth,
    isActiveThisMonth,
    setIsActiveThisMonth,
}) => {
    const { pathname } = useLocation()
    const paths = pathname.split('/').filter(path => path !== '')
    const queryParams = new URLSearchParams(window.location.search)
    const { userInfo } = useSelector(getAllUserInfo)

    const [isExpand, setIsExpand] = useState(false)

    const handleExpandFilter = () => {
        setIsExpand(!isExpand)
    }

    const resetPeriodBtn = () => {
        if (isActiveThisMonth) {
            setIsActiveThisMonth(false)
        }
        if (isActiveLastMonth) {
            setIsActiveLastMonth(false)
        }
    }

    /**
     *  시작일 선택 시
     * @param dates
     */
    const handleStartDateChange = dates => {
        setStartDateRange(dates)
        const startSelect = dayjs(dates).format('YYYYMMDD')

        if (dates === null) {
            updateQueryStringAndNavigate(startDate, '')
            resetPeriodBtn()
        } else {
            if (paths[1] === 'rental') {
                // 대여일자
                updateQueryStringAndNavigate('dispatchDate', startSelect)
            } else {
                updateQueryStringAndNavigate(startDate, startSelect)
            }
            resetPeriodBtn()
        }
    }

    /**
     * 종료일 선택 시
     * @param dates
     */
    const handleEndDateChange = dates => {
        setEndDateRange(dates)
        const endSelect = dayjs(dates).format('YYYYMMDD')

        if (dates === null) {
            updateQueryStringAndNavigate(endDate, '')
            resetPeriodBtn()
        } else {
            updateQueryStringAndNavigate(endDate, endSelect)
            resetPeriodBtn()
        }
    }

    return (
        <div className={`${styles.content_wrap} ${paths[0] === 'tools' ? styles.tools : ''}`}>
            <div className={`${styles.item_wrap} ${styles.tutor}`}>
                {paths[0] === 'program' && (
                    <>
                        {userInfo.regionId === 'all' && (
                            <LocalStatisticsFilter
                                updateQueryStringAndNavigate={updateQueryStringAndNavigate}
                                queryParams={queryParams}
                                type="program"
                            />
                        )}
                        <ProgramFilter
                            updateQueryStringAndNavigate={updateQueryStringAndNavigate}
                            queryParams={queryParams}
                        />
                    </>
                )}
                {paths[0] === 'tutors' && (
                    <>
                        {userInfo.regionId === 'all' && (
                            <LocalStatisticsFilter
                                updateQueryStringAndNavigate={updateQueryStringAndNavigate}
                                queryParams={queryParams}
                                type="tutors"
                            />
                        )}
                        <TutorFilter
                            updateQueryStringAndNavigate={updateQueryStringAndNavigate}
                            queryParams={queryParams}
                        />
                    </>
                )}
                {paths[0] === 'statistics' && pathname.split('/')[2] === undefined && (
                    <LocalStatisticsFilter
                        updateQueryStringAndNavigate={updateQueryStringAndNavigate}
                        queryParams={queryParams}
                        type="statistics"
                    />
                )}
                {paths[0] === 'report' && pathname.split('/')[2] === undefined && (
                    <ReportFilter
                        updateQueryStringAndNavigate={updateQueryStringAndNavigate}
                        queryParams={queryParams}
                        type="report"
                    />
                )}
                {pathname.split('/')[2] === 'total' && (
                    <StatisticsFilter
                        updateQueryStringAndNavigate={updateQueryStringAndNavigate}
                        queryParams={queryParams}
                    />
                )}
                {isExpand && (
                    <ExtraBtn
                        handleExpandFilter={handleExpandFilter}
                        handleResetQuerystring={handleResetQuerystring}
                        isExpand={isExpand}
                    />
                )}
                {paths[0] === 'tools' && paths[1] === 'inventory' && (
                    <>
                        {userInfo.regionId === 'all' && (
                            <LocalStatisticsFilter
                                updateQueryStringAndNavigate={updateQueryStringAndNavigate}
                                queryParams={queryParams}
                                type="tools"
                            />
                        )}
                        <InventoryFilter
                            updateQueryStringAndNavigate={updateQueryStringAndNavigate}
                            queryParams={queryParams}
                            startDateRange={startDateRange}
                            handleStartDateChange={handleStartDateChange}
                            handleEndDateChange={handleEndDateChange}
                            handleResetQuerystring={handleResetQuerystring}
                        />
                    </>
                )}
                {paths[0] === 'tools' && paths[1] === 'rental' && (
                    <>
                        {userInfo.regionId === 'all' && (
                            <LocalStatisticsFilter
                                updateQueryStringAndNavigate={updateQueryStringAndNavigate}
                                queryParams={queryParams}
                                type="tools"
                            />
                        )}
                        <RentalFilter
                            updateQueryStringAndNavigate={updateQueryStringAndNavigate}
                            queryParams={queryParams}
                            startDateRange={startDateRange}
                            handleStartDateChange={handleStartDateChange}
                            handleEndDateChange={handleEndDateChange}
                            handleResetQuerystring={handleResetQuerystring}
                        />
                    </>
                )}
                {paths[0] !== 'statistics' && paths[0] !== 'report' && (
                    <ExtraBtn
                        handleStartDateChange={handleStartDateChange}
                        handleEndDateChange={handleEndDateChange}
                        handleExpandFilter={handleExpandFilter}
                        handleResetQuerystring={handleResetQuerystring}
                        isExpand={isExpand}
                    />
                )}
                {paths[0] === 'survey' && (
                    <SurveyFilter
                        queryParams={queryParams}
                        updateQueryStringAndNavigate={updateQueryStringAndNavigate}
                        startDateRange={startDateRange}
                        setStartDateRange={setStartDateRange}
                        endDateRange={endDateRange}
                        setEndDateRange={setEndDateRange}
                        isActiveLastMonth={isActiveLastMonth}
                        setIsActiveLastMonth={setIsActiveLastMonth}
                        isActiveThisMonth={isActiveThisMonth}
                        setIsActiveThisMonth={setIsActiveThisMonth}
                        handleStartDateChange={handleStartDateChange}
                        handleEndDateChange={handleEndDateChange}
                        startDate={startDate}
                        endDate={endDate}
                    />
                )}
            </div>
            {paths[0] === 'program' && isExpand && (
                <ExpandProgram
                    updateQueryStringAndNavigate={updateQueryStringAndNavigate}
                    queryParams={queryParams}
                    startDateRange={startDateRange}
                    setStartDateRange={setStartDateRange}
                    endDateRange={endDateRange}
                    setEndDateRange={setEndDateRange}
                    isActiveLastMonth={isActiveLastMonth}
                    setIsActiveLastMonth={setIsActiveLastMonth}
                    isActiveThisMonth={isActiveThisMonth}
                    setIsActiveThisMonth={setIsActiveThisMonth}
                    handleStartDateChange={handleStartDateChange}
                    handleEndDateChange={handleEndDateChange}
                    startDate={startDate}
                    endDate={endDate}
                />
            )}
            {paths[0] === 'tutors' && isExpand && (
                <ExpandTutors
                    updateQueryStringAndNavigate={updateQueryStringAndNavigate}
                    queryParams={queryParams}
                    startDateRange={startDateRange}
                    setStartDateRange={setStartDateRange}
                    endDateRange={endDateRange}
                    setEndDateRange={setEndDateRange}
                    isActiveLastMonth={isActiveLastMonth}
                    setIsActiveLastMonth={setIsActiveLastMonth}
                    isActiveThisMonth={isActiveThisMonth}
                    setIsActiveThisMonth={setIsActiveThisMonth}
                    handleStartDateChange={handleStartDateChange}
                    handleEndDateChange={handleEndDateChange}
                    startDate={startDate}
                    endDate={endDate}
                />
            )}
            {/* {paths[0] === 'statistics' && isExpand && (
                <ExpandStatistics
                    updateQueryStringAndNavigate={updateQueryStringAndNavigate}
                    queryParams={queryParams}
                />
            )} */}
        </div>
    )
}

export default FilterArea
