import FilterButton from 'components/AntD/Button/FilterButton'
import { useEffect } from 'react'
import { CheckFilterIcon, ClassIcon, ContractIcon, ExpiredIcon } from 'assets/Icons'
import { getCurrentMonthDates, getLastMonthDates } from 'utils/utilCommon'
import { DatePicker } from 'antd'
import dayjs from 'dayjs'
import styles from '../filter.module.scss'

/**
 *
 * 
 * @param startDateRange
 * @param endDateRange
 * @param handleStartDateChange
 * @param handleEndDateChange
 * @param isActiveLastMonth,
 * @param handleLastMonth,
 * @param isActiveThisMonth,
 * @param handleThisMonth,

 */
const ExpandTutors = ({
    queryParams,
    updateQueryStringAndNavigate,
    startDateRange,
    setStartDateRange,
    endDateRange,
    setEndDateRange,
    isActiveLastMonth,
    setIsActiveLastMonth,
    isActiveThisMonth,
    setIsActiveThisMonth,
    handleEndDateChange,
    handleStartDateChange,
    endDate,
    startDate,
}) => {
    const employmentStatus = 'employmentStatus'
    const teacherCertification = 'teacherCertification'
    const teachingExperienceYn = 'teachingExperienceYn'
    const educationLevel = 'educationLevel'
    const employmentType = 'employmentType'
    const [currentMonthStartDate, currentMonthEndDate] = getCurrentMonthDates()
    const [lastMonthStartDate, lastMonthEndDate] = getLastMonthDates()
    const currentEmploymentStatus = queryParams.get(employmentStatus) || ''
    const currentTeacherCertification = queryParams.get(teacherCertification) || ''
    const currentTeachingExperienceYn = queryParams.get(teachingExperienceYn) || ''
    const currentEducationLevel = queryParams.get(educationLevel) || ''
    const currentEmploymentType = queryParams.get(employmentType) || ''
    // 저번달
    const lastMonthStart = lastMonthStartDate.format('YYYYMMDD')
    const lastMonthEnd = lastMonthEndDate.format('YYYYMMDD')

    // 이번달
    const currentMonthStart = currentMonthStartDate.format('YYYYMMDD')
    const currentMonthEnd = currentMonthEndDate.format('YYYYMMDD')

    useEffect(() => {
        const currentStartDate = queryParams.get('startDate')
        const currentEndDate = queryParams.get('endDate')

        if (currentStartDate) {
            setStartDateRange(dayjs(currentStartDate, 'YYYYMMDD'))
        }
        if (currentEndDate) {
            setEndDateRange(dayjs(currentEndDate, 'YYYYMMDD'))
        }
    }, [setStartDateRange, setEndDateRange])

    // 지난달 버튼 클릭 시
    const handleLastMonth = () => {
        if (isActiveLastMonth) {
            updateQueryStringAndNavigate(startDate, lastMonthStart)
            updateQueryStringAndNavigate(endDate, lastMonthEnd)
            queryParams.delete('startDate')
            queryParams.delete('endDate')
            setStartDateRange(null)
            setEndDateRange(null)
            setIsActiveLastMonth(false)
        } else {
            updateQueryStringAndNavigate(startDate, lastMonthStart)
            updateQueryStringAndNavigate(endDate, lastMonthEnd)
            setStartDateRange(lastMonthStartDate)
            setEndDateRange(lastMonthEndDate)
            setIsActiveLastMonth(true)
            setIsActiveThisMonth(false)
        }
    }

    // 이번달 버튼 클릭 시
    const handleThisMonth = () => {
        if (isActiveThisMonth) {
            updateQueryStringAndNavigate(startDate, currentMonthStart)
            updateQueryStringAndNavigate(endDate, currentMonthEnd)
            queryParams.delete('startDate')
            queryParams.delete('endDate')
            setStartDateRange(null)
            setEndDateRange(null)
            setIsActiveThisMonth(false)
        } else {
            updateQueryStringAndNavigate(startDate, currentMonthStart)
            updateQueryStringAndNavigate(endDate, currentMonthEnd)
            setStartDateRange(currentMonthStartDate)
            setEndDateRange(currentMonthEndDate)
            setIsActiveThisMonth(true)
            setIsActiveLastMonth(false)
        }
    }
    return (
        <>
            <div className={styles.item_wrap}>
                <span className={`body2 ${styles.title}`}>학력 및 자격</span>
                <div className={styles.btn_wrap}>
                    <FilterButton
                        icon={<CheckFilterIcon />}
                        title="고졸"
                        currentParamValue={currentEducationLevel}
                        onClick={() => updateQueryStringAndNavigate(educationLevel, '고졸')}
                        filterColor="black"
                    />
                    <FilterButton
                        icon={<CheckFilterIcon />}
                        title="전문대졸"
                        currentParamValue={currentEducationLevel}
                        onClick={() => updateQueryStringAndNavigate(educationLevel, '전문대졸')}
                        filterColor="black"
                    />
                    <FilterButton
                        icon={<CheckFilterIcon />}
                        title="대졸"
                        currentParamValue={currentEducationLevel}
                        onClick={() => updateQueryStringAndNavigate(educationLevel, '대졸')}
                        filterColor="black"
                    />
                    <FilterButton
                        icon={<CheckFilterIcon />}
                        title="석사"
                        currentParamValue={currentEducationLevel}
                        onClick={() => updateQueryStringAndNavigate(educationLevel, '석사')}
                        filterColor="black"
                    />
                </div>
                <div className={styles.btn_wrap}>
                    <FilterButton
                        icon={<CheckFilterIcon />}
                        title="교원자격"
                        currentParamValue={currentTeacherCertification}
                        onClick={() => updateQueryStringAndNavigate(teacherCertification, 'Y')}
                        filterColor="black"
                    />
                    <FilterButton
                        icon={<CheckFilterIcon />}
                        title="강의경험"
                        currentParamValue={currentTeachingExperienceYn}
                        onClick={() => updateQueryStringAndNavigate(teachingExperienceYn, 'Y')}
                        filterColor="black"
                    />
                </div>
            </div>
            <div className={styles.item_wrap}>
                <span className={`body2 ${styles.title}`}>계약관련</span>
                <div className={styles.btn_wrap}>
                    <FilterButton
                        icon={<ContractIcon />}
                        title="대기"
                        currentParamValue={currentEmploymentStatus}
                        onClick={() => updateQueryStringAndNavigate(employmentStatus, '대기')}
                        filterColor="yellow"
                    />
                    <FilterButton
                        icon={<ClassIcon />}
                        title="재직중"
                        currentParamValue={currentEmploymentStatus}
                        onClick={() => updateQueryStringAndNavigate(employmentStatus, '재직중')}
                        filterColor="blue"
                    />
                    <FilterButton
                        icon={<ExpiredIcon />}
                        title="계약만료"
                        currentParamValue={currentEmploymentStatus}
                        onClick={() => updateQueryStringAndNavigate(employmentStatus, '계약만료')}
                        filterColor="darkgray"
                    />
                </div>
                <div className={styles.btn_wrap}>
                    <FilterButton
                        icon={<CheckFilterIcon />}
                        title="직접채용"
                        currentParamValue={currentEmploymentType}
                        onClick={() => updateQueryStringAndNavigate(employmentType, '직접채용')}
                        filterColor="black"
                    />
                    <FilterButton
                        icon={<CheckFilterIcon />}
                        title="협동조합"
                        currentParamValue={currentEmploymentType}
                        onClick={() => updateQueryStringAndNavigate(employmentType, '협동조합')}
                        filterColor="black"
                    />
                </div>
            </div>
            <div className={styles.item_wrap}>
                <span className={`body2 ${styles.title}`}>계약기간</span>
                <div className={styles.btn_wrap}>
                    <DatePicker
                        format="YYYY-MM-DD"
                        value={startDateRange}
                        className={styles.h100}
                        onChange={handleStartDateChange}
                        placeholder="시작일"
                    />
                    <div className={styles.tilde}>~</div>
                    <DatePicker
                        format="YYYY-MM-DD"
                        value={endDateRange}
                        className={styles.h100}
                        onChange={handleEndDateChange}
                        placeholder="종료일"
                    />
                </div>
                <div className={styles.btn_wrap}>
                    <FilterButton
                        icon={<CheckFilterIcon />}
                        title="지난달"
                        currentParamValue={isActiveLastMonth}
                        filterColor="black"
                        onClick={handleLastMonth}
                    />
                    <FilterButton
                        icon={<CheckFilterIcon />}
                        title="이번달"
                        currentParamValue={isActiveThisMonth}
                        filterColor="black"
                        onClick={handleThisMonth}
                    />
                </div>
            </div>
        </>
    )
}

export default ExpandTutors
