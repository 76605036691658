import { Flex } from 'antd'

import SelectBox from '../../AntD/Select'
import styles from '../filter.module.scss'
import LocalStatisticsFilter from './LocalStatisticsFilter'

const ReportFilter = ({ updateQueryStringAndNavigate, queryParams, type }) => {
    const key = 'monthOfReport'
    const initialReport = queryParams.get('monthOfReport') || 'all'

    const reports = [
        { value: 'all', label: '전체' },
        { value: '20240101', label: '2024년 01월' },
        { value: '20240201', label: '2024년 02월' },
        { value: '20240301', label: '2024년 03월' },
        { value: '20240401', label: '2024년 04월' },
        { value: '20240501', label: '2024년 05월' },
        { value: '20240601', label: '2024년 06월' },
        { value: '20240701', label: '2024년 07월' },
        { value: '20240801', label: '2024년 08월' },
        { value: '20240901', label: '2024년 09월' },
        { value: '20241001', label: '2024년 10월' },
        { value: '20241101', label: '2024년 11월' },
        { value: '20241201', label: '2024년 12월' },
        { value: '20250101', label: '2025년 01월' },
        { value: '20250201', label: '2025년 02월' },
        { value: '20250301', label: '2025년 03월' },
        { value: '20250401', label: '2025년 04월' },
        { value: '20250501', label: '2025년 05월' },
        { value: '20250601', label: '2025년 06월' },
        { value: '20250701', label: '2025년 07월' },
        { value: '20250801', label: '2025년 08월' },
        { value: '20250901', label: '2025년 09월' },
        { value: '20251001', label: '2025년 10월' },
        { value: '20251101', label: '2025년 11월' },
        { value: '20251201', label: '2025년 12월' },
    ]

    return (
        <>
            <LocalStatisticsFilter
                updateQueryStringAndNavigate={updateQueryStringAndNavigate}
                queryParams={queryParams}
                type={type}
            />
            <Flex align="start">
                <span className={`body2 ${styles.title}`}>작성 년/월</span>
                <SelectBox
                    style={{ height: '50px' }}
                    onChange={value => updateQueryStringAndNavigate(key, value)}
                    initialvalues={initialReport}
                    options={reports}
                />
            </Flex>
        </>
    )
}

export default ReportFilter
