import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { Flex, Image, Progress, Tooltip, Space, Divider } from 'antd'
import { BannerIconSystem, AllDocIcon } from 'assets/Icons'
import PageBanner from 'layout/PageBanner'
import ColorButton from 'components/AntD/Button/ColorButton'
import StatisticsTableDialog from 'components/AntD/Dialog/StatisticsTableDialog'
import FilterLayout from 'components/Filter/FilterLayout'
import { getUser } from 'store/Slices/user'

import { supabase } from 'api/axios.instance'

import mapYear from 'assets/images/total-map.svg'
import mapYearArrow1 from 'assets/images/arrow-kk.svg'
import mapYearArrow2 from 'assets/images/arrow-dg.svg'
import mapYearArrow3 from 'assets/images/arrow-kb.svg'
import mapYearArrow4 from 'assets/images/arrow-kn.svg'
import mapYearArrow5 from 'assets/images/arrow-jb.svg'
import mapYearArrow6 from 'assets/images/arrow-jn.svg'
import mapYearArrow7 from 'assets/images/arrow-gj.svg'
import mapYearArrow8 from 'assets/images/arrow-cn.svg'
import mapYearArrow9 from 'assets/images/arrow-cb.svg'
import mapYearArrow10 from 'assets/images/arrow-kw.svg'
import mapYearArrow11 from 'assets/images/arrow-jj.svg'
import mapYearArrow12 from 'assets/images/arrow-icn.svg'
import mapYearArrow13 from 'assets/images/arrow-ws.svg'
import StatisticsTutorTableDialog from 'components/AntD/Dialog/StatisticsTutorTableDialog'
import styles from '../statistics.module.scss'

// 지역 통계
const TotalStatistics = () => {
    const { isMaster } = useSelector(getUser)
    const { pathname } = useLocation()

    const [programTotal, setProgramTotal] = useState([])
    const [tutorTotal, setTutorTotal] = useState([])

    const [isModalOpen, setIsModalOpen] = useState(false)
    const location = useLocation()
    const navigate = useNavigate()
    const queryParams = new URLSearchParams(window.location.search)
    const [selectedYear, setSelectedYear] = useState(queryParams.get('year') || '2024')

    /**
     * 사용자 분기처리
     */
    useEffect(() => {
        if (!isMaster) {
            navigate('/statistics')
        }
    }, [pathname])

    const [mapInfoYear, setMapInfoYear] = useState([
        {
            key: '1',
            region: '강원',
            data: [],
            top: '60px',
            position: 'right',
            active: true,
            map: mapYearArrow10,
        },
        {
            key: '2',
            region: '광주',
            data: [],
            top: '530px',
            position: 'left',
            active: true,
            map: mapYearArrow7,
        },
        {
            key: '3',
            region: '인천',
            data: [],
            top: '170px',
            position: 'left',
            active: true,
            map: mapYearArrow12,
        },

        {
            key: '4',
            region: '경남',
            data: [],
            top: '660px',
            position: 'right',
            active: true,
            map: mapYearArrow4,
        },
        {
            key: '5',
            region: '경북',
            data: [],
            top: '300px',
            position: 'right',
            active: true,
            map: mapYearArrow3,
        },
        {
            key: '6',
            region: '대구',
            data: [],
            top: '420px',
            position: 'right',
            active: true,
            map: mapYearArrow2,
        },
        {
            key: '7',
            region: '울산',
            data: [],
            top: '540px',
            position: 'right',
            active: true,
            map: mapYearArrow13,
        },

        {
            key: '8',
            region: '경기',
            top: '50px',
            data: [],
            position: 'left',
            active: true,
            map: mapYearArrow1,
        },
        {
            key: '9',
            region: '전남',
            data: [],
            top: '650px',
            position: 'left',
            active: true,
            map: mapYearArrow6,
        },
        {
            key: '10',
            region: '전북',
            data: [],
            top: '410px',
            position: 'left',
            active: true,
            map: mapYearArrow5,
        },
        {
            key: '11',
            region: '제주',
            data: [],
            top: '770px',
            position: 'left',
            active: true,
            map: mapYearArrow11,
        },
        {
            key: '12',
            region: '충남',
            data: [],
            top: '290px',
            position: 'left',
            active: true,
            map: mapYearArrow8,
        },
        {
            key: '13',
            region: '충북',
            data: [],
            top: '180Px',
            position: 'right',
            active: true,
            map: mapYearArrow9,
        },
    ])

    /**
     * year 쿼리에서 selectedYear 저장
     */
    const fetchDataBasedOnQueryParams = () => {
        const yearParam = queryParams.get('year')
        const validYears = ['2024', '2023', '2022', '2021', '2020', '2019']

        setSelectedYear(validYears.includes(yearParam) ? yearParam : '2024')
    }

    /**
     * 쿼리가 바뀔 때 , setSelectedYear
     */
    useEffect(() => {
        fetchDataBasedOnQueryParams()
    }, [location.search])

    const updateMapInfoYear = programTotal => {
        const newArrayWithMatchingProgram = mapInfoYear.map(map => {
            const matchingProgram = programTotal.find(program => program.region === map.region)
            return matchingProgram ? { ...map, data: matchingProgram, active: true } : { ...map, active: false }
        })

        setMapInfoYear(newArrayWithMatchingProgram)
    }

    const getTotalStatistics = async year => {
        try {
            const { data, error } = await supabase
                .from('statistics_total_student')
                .select()
                .eq('year', year)
                .order('key', { ascending: true })

            if (error) {
                console.error(error.message)
            } else {
                setProgramTotal(data)
                updateMapInfoYear(data)
            }
        } catch (error) {
            console.error(error.message)
        }
    }

    const getTutorTotalStatistics = async year => {
        try {
            const { data, error } = await supabase
                .from('statistics_total_tutor')
                .select()
                .eq('year', year)
                .order('id', { ascending: true })

            if (error) {
                console.error(error.message)
            } else {
                setTutorTotal(data)
            }
        } catch (error) {
            console.error(error.message)
        }
    }

    /**
     * querystring update
     * @param paramName
     * @param paramValue
     * @param loading
     */
    const updateQueryStringAndNavigate = (paramName, paramValue, loading) => {
        const { pathname } = location
        const previousParams = queryParams.get(paramName)

        // 기존값과 동일할 시 변경하지 않음
        if (paramName === 'regionId' && paramValue === previousParams) {
            return
        }

        // 새로운 파라미터 추가 (단, 값이 비어있지 않을 때)
        if (paramValue) {
            if (previousParams === paramValue) {
                if (paramName !== 'sort' && paramName !== 'search') {
                    queryParams.delete(paramName)
                }
            } else {
                queryParams.set(paramName, paramValue)
            }
        }

        const newQueryString = queryParams.toString()
        const newLocation = newQueryString ? `${pathname}?${newQueryString}` : pathname

        if (loading) {
            loading(false)
        }
        navigate(newLocation)
    }

    const removePercentAndRound = inputString => {
        if (inputString == null) {
            return null
        }

        const stringWithoutPercent = inputString.replace(/%$/, '')

        const numericValue = parseFloat(stringWithoutPercent)

        if (!Number.isFinite(numericValue) || Number.isNaN(numericValue)) {
            throw new Error('Invalid numeric value')
        }

        const roundedValue = Math.round(numericValue * 100) / 100

        return roundedValue
    }

    //파라미터가 초기화되었을 때 2024년조회
    useEffect(() => {
        const setYearQueryParam = () => {
            const yearParam = queryParams.get('year')
            if (!yearParam) {
                queryParams.set('year', '2024')
                const newQueryString = queryParams.toString()
                const newLocation = newQueryString ? `/statistics/total?${newQueryString}` : '/statistics/total'
                navigate(newLocation)
            }
        }

        setYearQueryParam()

        getTotalStatistics(selectedYear)
        getTutorTotalStatistics(selectedYear)
        setMapInfoYear(prevMapInfoYear => prevMapInfoYear.map(map => ({ ...map, active: false })))
    }, [selectedYear])

    return (
        <PageBanner title="성과 분석" bannerIcon={<BannerIconSystem />}>
            <div>
                <FilterLayout updateQueryStringAndNavigate={updateQueryStringAndNavigate} />
            </div>
            <div className={styles.mb_md}>
                <Flex justify="space-between">
                    <div className={styles.title_large}>{selectedYear}년</div>
                    <Space>
                        <ColorButton onClick={() => setIsModalOpen('student')} icon={<AllDocIcon />}>
                            수혜학생통계
                        </ColorButton>
                        <ColorButton onClick={() => setIsModalOpen('tutor')} icon={<AllDocIcon />}>
                            강사통계
                        </ColorButton>
                    </Space>
                </Flex>
                <StatisticsTableDialog
                    className={styles.modal}
                    selectedYear={selectedYear}
                    modalOpen={isModalOpen === 'student'}
                    setModalOpen={setIsModalOpen}
                    isCancel
                    dataSource={programTotal}
                />
                <StatisticsTutorTableDialog
                    className={styles.modal}
                    selectedYear={selectedYear}
                    modalOpen={isModalOpen === 'tutor'}
                    setModalOpen={setIsModalOpen}
                    isCancel
                    dataSource={tutorTotal}
                />
            </div>
            <div className={styles.mapAreaYear}>
                <div className={styles.center}>
                    <Image src={mapYear} preview={false} className={styles.mapPosition} />
                </div>
                {mapInfoYear
                    .filter(item => item.active === true)
                    .map((mapInfoYear, index) => {
                        const leftBox = (
                            <div
                                key={mapInfoYear.key}
                                className={` ${styles.cardbox}  ${styles.left}`}
                                style={{ top: mapInfoYear.top }}
                            >
                                <div className={styles.inner}>
                                    <span className={styles.title}>{mapInfoYear.region}</span>
                                    <div className={styles.circleArea}>
                                        <Tooltip title="찾아가는 교육비율">
                                            <Progress
                                                strokeColor="#FF6F4B"
                                                status="normal"
                                                type="circle"
                                                percent={removePercentAndRound(mapInfoYear.data.visiting_ratio)}
                                            />
                                            <div className={styles.progressLabel}>
                                                찾아가는
                                                <span>{mapInfoYear.data.visiting_ratio}%</span>
                                            </div>
                                        </Tooltip>
                                    </div>
                                    <Divider type="vertical" />
                                    <div>
                                        <div>
                                            수혜자수
                                            <Divider type="vertical" />
                                            <span>{mapInfoYear.data.number_of_benefited}명</span>
                                        </div>
                                        <div className={styles.humanGraph}>
                                            <div className={styles.bar}>
                                                <div
                                                    className={styles.humanFillBlue}
                                                    style={{
                                                        width: `${removePercentAndRound(
                                                            mapInfoYear.data.benefited_ratio,
                                                        )}%`,
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            강사채용수
                                            <Divider type="vertical" />
                                            <span>{mapInfoYear.data.number_of_trained}명</span>
                                        </div>
                                        <div className={styles.humanGraph}>
                                            <div className={styles.bar}>
                                                <div
                                                    className={styles.humanFillGreen}
                                                    style={{
                                                        width: `${removePercentAndRound(
                                                            mapInfoYear.data.hired_ratio,
                                                        )}%`,
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Image
                                    src={mapInfoYear.map}
                                    preview={false}
                                    className={
                                        mapInfoYear.region === '전남' || mapInfoYear.region === '제주'
                                            ? styles.leftArrow
                                            : styles.arrow
                                    }
                                    alt={index}
                                />
                            </div>
                        )
                        const rightBox = (
                            <div
                                key={mapInfoYear.key}
                                className={`${styles.cardbox} ${styles.right}`}
                                style={{ top: mapInfoYear.top }}
                            >
                                <Image
                                    src={mapInfoYear.map}
                                    preview={false}
                                    className={
                                        mapInfoYear.region === '경남'
                                            ? styles.arrowTop
                                            : mapInfoYear.region === '울산'
                                            ? styles.arrowBtm
                                            : styles.rightArrow
                                    }
                                    alt={index}
                                />
                                <div className={styles.inner}>
                                    <span className={`${styles.title}`}>{mapInfoYear.region}</span>
                                    <div className={styles.circleArea}>
                                        <Tooltip title="찾아가는 교육비율">
                                            <Progress
                                                strokeColor="#FF6F4B"
                                                status="normal"
                                                type="circle"
                                                percent={` ${removePercentAndRound(mapInfoYear.data.visiting_ratio)}`}
                                            />

                                            <div className={styles.progressLabel}>
                                                찾아가는
                                                <span>{mapInfoYear.data.visiting_ratio}%</span>
                                            </div>
                                        </Tooltip>
                                    </div>
                                    <Divider type="vertical" />
                                    <div>
                                        <div>
                                            수혜자수
                                            <Divider type="vertical" />
                                            <span>{mapInfoYear.data.number_of_benefited}명</span>
                                        </div>
                                        <div className={styles.humanGraph}>
                                            <div className={styles.bar}>
                                                <div
                                                    className={styles.humanFillBlue}
                                                    style={{
                                                        width: `${removePercentAndRound(
                                                            mapInfoYear.data.benefited_ratio,
                                                        )}%`,
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            강사채용수
                                            <Divider type="vertical" />
                                            <span>{mapInfoYear.data.number_of_trained}명</span>
                                        </div>
                                        <div className={styles.humanGraph}>
                                            <div className={styles.bar}>
                                                <div
                                                    className={styles.humanFillGreen}
                                                    style={{
                                                        width: `${removePercentAndRound(
                                                            mapInfoYear.data.hired_ratio,
                                                        )}%`,
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                        return mapInfoYear.position === 'left' ? leftBox : rightBox
                    })}
            </div>
        </PageBanner>
    )
}

export default TotalStatistics
