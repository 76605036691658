import PageBanner from 'layout/PageBanner'
import { BannerIconAccount } from 'assets/Icons'
import ColorButton from 'components/AntD/Button/ColorButton'
import InputSection from 'components/AntD/InputSection/password'
import { Flex, Form, message } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getAllUserInfo, logoutUserAsync } from 'store/Slices/user'
import { useState } from 'react'
import { supabase } from 'api/axios.instance'
import styles from '../account.module.scss'

export const PasswordPage = () => {
    const [form] = Form.useForm()
    const [messageApi, contextHolder] = message.useMessage()

    const { userInfo } = useSelector(getAllUserInfo)
    const { userId } = userInfo
    const dispatch = useDispatch()

    const navigate = useNavigate()
    const updatePassword = async newPassword => {
        try {
            const { data, error } = await supabase.auth.updateUser({
                password: newPassword,
            })

            if (error) {
                console.error('Error updating password:', error)
                return null
            }

            if (!data) {
                console.error('No data returned from password update')
                return null
            }

            // 2. user_info 테이블의 passwd 컬럼 업데이트 (Auth 비밀번호 변경 성공 시만 실행)
            const { error: updateError } = await supabase
                .from('user_info')
                .update({ passwd: newPassword })
                .eq('user_id', userId)
                .select()

            if (updateError) {
                console.error('Error updating user_info table:', updateError)
                return null
            }

            return data
        } catch (err) {
            console.error('Unexpected error:', err)
            return null
        }
    }
    const resetPasswordForEmail = async () => {
        try {
            // recovery 함수 도메인 google.com으로 고정 - supabase API - 단순 이메일 형식 확인용도
            const { error } = await supabase.auth.resetPasswordForEmail(`${userId}@google.com`)

            if (error) {
                console.error(error) // 오류 디버깅

                if (error.status === 500) {
                    messageApi.open({
                        type: 'error',
                        content: '서버 오류가 발생했습니다. 관리자에게 문의해주세요.',
                        duration: 3,
                    })
                } else if (error.status === 429) {
                    messageApi.open({
                        type: 'error',
                        content: '현재 서버 요청이 많아 한 시간 뒤 시도해주세요.',
                        duration: 3,
                    })
                } else {
                    messageApi.open({
                        type: 'error',
                        content: error.message || '알 수 없는 오류',
                        duration: 3,
                    })
                }

                return false
            }

            return true
        } catch (err) {
            messageApi.open({
                type: 'error',
                content: '오류가 발생했습니다. 다시 시도해주세요.',
                duration: 3,
            })
            console.error(err)

            return false
        }
    }

    //submit 함수
    const onFinish = async () => {
        const { newPassword } = form.getFieldsValue()

        const resetResult = await resetPasswordForEmail()
        if (resetResult && Object.keys(resetResult).length === 0) {
            const passwordUpdateResult = await updatePassword(newPassword)
            if (passwordUpdateResult) {
                messageApi.open({
                    type: 'success',
                    content: '비밀번호가 변경되었습니다. 로그인 페이지로 이동합니다.',
                    duration: 3,
                })

                setTimeout(() => {
                    dispatch(logoutUserAsync())
                }, 800)
            } else {
                await messageApi.open({
                    type: 'error',
                    content: '비밀번호가 변경되지 않았습니다. 다시 시도해주세요.',
                })
            }
        }
    }

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo)
    }

    // 기존 비밀번호 검증 상태
    const [validPrevPassword, setValidPrevPassword] = useState(null)
    // 기존 비밀번호 검증 함수
    const verifyCurrentPassword = async password => {
        const { error } = await supabase.auth.signInWithPassword({
            email: `${userId}@swedulink.com`,
            password,
        })

        if (error) {
            setValidPrevPassword(false)
            form.setFields([{ name: 'prevPassword', errors: ['기존 비밀번호가 일치하지 않습니다.'] }])
            return false
        }
        form.setFields([{ name: 'prevPassword', errors: [] }])
        setValidPrevPassword(true)
        return true
    }

    // 기존 비밀번호 입력 필드 blur 이벤트 핸들러
    const handlePrevPasswordBlur = async () => {
        const prevPassword = form.getFieldValue('prevPassword')
        if (prevPassword) {
            await verifyCurrentPassword(prevPassword)
        }
    }

    return (
        <>
            {contextHolder}
            <PageBanner title="비밀번호 변경" bannerIcon={<BannerIconAccount />}>
                <Form
                    name="password"
                    form={form}
                    initialValues={{ remember: true }}
                    onFinish={() => {
                        if (validPrevPassword) {
                            onFinish()
                        } else {
                            form.setFields([{ name: 'prevPassword', errors: ['기존 비밀번호가 일치하지 않습니다.'] }])
                        }
                    }} //onsubmit
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <div className={styles.container}>
                        <InputSection
                            label="기존 비밀번호"
                            name="prevPassword"
                            handleBlur={handlePrevPasswordBlur}
                            handleChange={() => setValidPrevPassword(true)}
                            leftCol={4}
                            rightCol={20}
                            rules={[
                                {
                                    required: true,
                                    message: '필수값을 확인해주세요.',
                                },
                            ]}
                        />
                        <InputSection
                            label="신규 비밀번호"
                            name="newPassword"
                            noBorder
                            leftCol={4}
                            rightCol={20}
                            rules={[
                                {
                                    required: true,
                                    message: '필수값을 확인해주세요.',
                                },
                                {
                                    pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/,
                                    message: '영문, 숫자 조합 6자 이상이어야 합니다.',
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('prevPassword') !== value) {
                                            return Promise.resolve()
                                        }
                                        return Promise.reject(new Error('기존 비밀번호와 신규 비밀번호가 같습니다.'))
                                    },
                                }),
                            ]}
                        />
                        <InputSection
                            label="비밀번호 확인"
                            name="confirmPassword"
                            rules={[
                                {
                                    required: true,
                                    message: '필수값을 확인해주세요.',
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('newPassword') === value) {
                                            return Promise.resolve()
                                        }
                                        return Promise.reject(new Error('비밀번호가 일치하지 않습니다.'))
                                    },
                                }),
                            ]}
                            noBorder
                            leftCol={4}
                            rightCol={20}
                        />
                        <Flex justify="center" gap={8} style={{ marginTop: 20 }}>
                            <Form.Item>
                                <ColorButton size="large" type="primary" key="back" htmlType="submit">
                                    확인
                                </ColorButton>
                            </Form.Item>
                            <ColorButton size="large" key="back" onClick={() => navigate(-1)}>
                                취소
                            </ColorButton>
                        </Flex>
                    </div>
                </Form>
            </PageBanner>
        </>
    )
}
