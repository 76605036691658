import dayjs from 'dayjs'
import { Link, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Flex } from 'antd'
import { supabase } from 'api/axios.instance'
import * as XLSX from 'xlsx'
import { v4 as uuidv4 } from 'uuid'

import { colorTheme } from 'assets/colorTheme'

import { postProgram, postProgramInstructorMatching } from 'api/program/program.api'
import { findDuplicatedTutorNames, postTutors } from 'api/tutors/tutors.api'
import { insertToolsInventory, findDuplicatedToolsNum } from 'api/tools/toolsInventory.api'

import { isValidDateFormat, removeParentheses } from 'utils/utilCommon'

import { getAllUserInfo } from 'store/Slices/user'

import ColorButton from 'components/AntD/Button/ColorButton'
import { DraggableUpload } from 'components/AntD/Upload'
import ListView from 'components/List/ListView'

import { DownloadIcon24 } from 'assets/Icons'
import styles from './register.module.scss'

const isInvalidDateError = `날짜 형식에 맞게 입력해주세요. (ex: YYYY-MM-DD)`
const isValidRentalStatusError = `유효하지 않은 대여 상태 값이 포함되어 있습니다.`
const isAnyToolUnmatchedError = '기존에 등록된 품목만 대여 가능합니다.'
const isAnyTutorUnmatchedError = '기존에 등록된 교사만 대여 가능합니다.'

/**
 * 교육관리, 강사관리 등록 공통 컴포넌트
 * @param jsonData
 * @param fileName
 * @param setDataState
 * @param modifiedData
 * @param handleConvertjsonData
 * @param setAlertOpen
 * @param setModalOpen
 * @param errorMessage
 * @param hasDuplicateTutorName
 * @param unRegisteredTutorName
 * @param matchingTutorInfo
 * @param duplicatedError
 * @param registerError
 * @param unregisterError
 * @param requiredNull
 * @param requiredNullError
 * @param isInvalidDate
 * @param isInvalidDateError
 * @param isValidRentalStatusError
 * @param isDuplicateToolsNum
 * @param duplicatedToolsNumError
 * @returns {JSX.Element}
 * @constructor
 */
const Register = ({
    jsonData,
    fileName,
    setDataState,
    modifiedData,
    handleConvertjsonData,
    setAlertOpen,
    setModalOpen,
    errorMessage,
    hasDuplicateTutorName,
    unRegisteredTutorName,
    matchingTutorInfo,
    duplicatedError,
    registerError,
    unregisterError,
    requiredNull,
    requiredNullError,
    isInvalidDate,
    isDuplicateToolsNum,
    duplicatedToolsNumError,
    isAnyToolUnmatched, // 교구재 매칭 여부 확인
    isAnyTutorUnmatched, // 대여자(강사) 매칭 여부 확인
    isValidRentalStatus,
}) => {
    const { blue } = colorTheme

    const { userInfo } = useSelector(getAllUserInfo)
    const { regionId } = userInfo
    const { pathname } = useLocation()

    const paths = pathname.split('/').filter(path => path !== '')
    const isProgramURL = paths[0] === 'program'
    const isTutorURL = paths[0] === 'tutors'
    const isInventoryURL = paths[1] === 'inventory'
    const isRentalURL = paths[1] === 'rental'
    // let tutorArr = [] // 교육 계획 등록 시 등록되어 있는 강사 정보 배열

    // 양식 다운로드
    const handleDownloadForm = () => {
        let fileURL = ''

        if (isProgramURL) {
            fileURL = `${process.env.REACT_APP_FILE_BUCKET_CLOUDFRONT_URL}/public/assets/form/%E1%84%80%E1%85%AD%E1%84%8B%E1%85%B2%E1%86%A8%E1%84%91%E1%85%B3%E1%84%85%E1%85%A9%E1%84%80%E1%85%B3%E1%84%85%E1%85%A2%E1%86%B7_%E1%84%8B%E1%85%A8%E1%84%89%E1%85%B5_2025.xlsx`
        }
        if (isTutorURL) {
            fileURL = `${process.env.REACT_APP_FILE_BUCKET_CLOUDFRONT_URL}/public/assets/form/%E1%84%80%E1%85%A1%E1%86%BC%E1%84%89%E1%85%A1%E1%84%80%E1%85%AA%E1%86%AB%E1%84%85%E1%85%B5_%E1%84%8B%E1%85%A8%E1%84%89%E1%85%B5_2025.xlsx`
        }
        if (isInventoryURL) {
            fileURL = `${process.env.REACT_APP_FILE_BUCKET_CLOUDFRONT_URL}/public/assets/form/%E1%84%8C%E1%85%A2%E1%84%80%E1%85%A9%E1%84%80%E1%85%AA%E1%86%AB%E1%84%85%E1%85%B5_%E1%84%8B%E1%85%A8%E1%84%89%E1%85%B5.xlsx`
        }
        if (isRentalURL) {
            fileURL = `${process.env.REACT_APP_FILE_BUCKET_CLOUDFRONT_URL}/public/assets/form/%E1%84%83%E1%85%A2%E1%84%8B%E1%85%A7%E1%84%87%E1%85%A1%E1%86%AB%E1%84%82%E1%85%A1%E1%86%B8%E1%84%80%E1%85%AA%E1%86%AB%E1%84%85%E1%85%B5_%E1%84%8B%E1%85%A8%E1%84%89%E1%85%B5.xlsx`
        }

        if (fileURL === '') {
            return false
        }
        const a = document.createElement('a')
        a.href = fileURL
        a.download = 'file-name.pdf'
        a.click()
        return true
    }

    /**
     * 현재 업로드한 엑셀에서 강사명 동명이인 확인
     * @param data
     * @returns {{indexes: *, name: *}[]|null}
     */
    const findDuplicateNames = data => {
        const nameIndexMap = {} // 이름과 해당 이름이 있는 배열의 인덱스를 저장할 객체

        for (let i = 0; i < data.length; i += 1) {
            const tutorName = data[i].tutor_name

            if (nameIndexMap[tutorName]) {
                // 이미 해당 이름이 맵에 있다면 동명이인이므로 배열의 인덱스를 추가
                nameIndexMap[tutorName].push(i)
            } else {
                // 해당 이름이 맵에 없다면 새로운 배열을 생성하여 인덱스 추가
                nameIndexMap[tutorName] = [i]
            }
        }

        // 동명이인이 있는 경우 해당 정보를 반환, 없으면 null 반환
        const duplicates = Object.entries(nameIndexMap)
            .filter(([, indexes]) => indexes.length > 1)
            .map(([name, indexes]) => ({ name, indexes }))

        return duplicates.length > 0 ? duplicates : null
    }

    /**
     * 중복 강사명으로 구성된 배열
     * @param data
     * @returns {unknown[]}
     */
    const getDuplicateNameArr = data => {
        const uniqueTutorNames = {}
        data.forEach(tutor => {
            const { tutorName } = tutor
            uniqueTutorNames[tutorName] = true
        })

        // 새로운 배열 생성
        return Object.keys(uniqueTutorNames)
    }

    /**
     * 현재 업로드한 엑셀에서 자산번호 중복 확인
     * @param data
     * @returns {{indexes: *, name: *}[]|null}
     */
    const findDuplicateToolsNum = data => {
        const toolsNumIndexMap = {} // 자산번호와 해당 자산번호가 있는 배열의 인덱스를 저장할 객체

        for (let i = 0; i < data.length; i += 1) {
            const toolsNum = data[i].tools_num

            if (toolsNumIndexMap[toolsNum]) {
                // 이미 해당 자산번호가 맵에 있다면 중복이므로 배열의 인덱스를 추가
                toolsNumIndexMap[toolsNum].push(i)
            } else {
                // 해당 자산번호가 맵에 없다면 새로운 배열을 생성하여 인덱스 추가
                toolsNumIndexMap[toolsNum] = [i]
            }
        }

        // 중복된 자산번호가 있는 경우 해당 정보를 반환, 없으면 null 반환
        const duplicates = Object.entries(toolsNumIndexMap)
            .filter(([, indexes]) => indexes.length > 1)
            .map(([toolsNum, indexes]) => ({ toolsNum, indexes }))

        return duplicates.length > 0 ? duplicates : null
    }

    const getDuplicateToolsNum = data => {
        const uniqueToolsNum = {}
        data.forEach(tools => {
            const { toolsNum } = tools
            uniqueToolsNum[toolsNum] = true
        })
        return Object.keys(uniqueToolsNum)
    }

    /**
     * 필수값 없는 경우 빨간색으로 표시
     * @param data
     * @param transformedData
     * @param file
     * @returns {Promise<void>}
     */
    const validateRequiredNull = async data => {
        const hasRequiredNull = data.some(item => {
            return Object.keys(item).some(key => {
                const isRequired = key.endsWith('*')
                const isEmpty = !item[key]

                return isRequired && isEmpty
            })
        })

        setDataState(prevState => ({
            ...prevState,
            requiredNull: hasRequiredNull,
        }))
    }

    /**
     * 교육계획관리에서
     * 주강사, 보조강사 등록 시
     * 강사가 DB에 존재하는지 확인
     * @param data
     * @param transformedData
     * @param file
     * @returns {Promise<void>}
     */

    const validateLeadInstructorAndAssistant = async (data, transformedData, file) => {
        // 업로드한 데이터 중 강사명만 파싱
        const tutorNamesArray = transformedData.flatMap(tutor => [
            ...(tutor.lead_Instructors || '').split(',').map(item => item.split('(')[0].trim()),
            ...(tutor.assistants || '').split(',').map(item => item.split('(')[0].trim()),
        ])

        const programYearArray = transformedData.map(tutor => tutor.registration_year)

        // DB에서 같은 지역 강사 동명이인 찾기 (강사 매칭)
        const res = await findDuplicatedTutorNames(tutorNamesArray, userInfo.regionId, programYearArray)

        // 등록된 강사 정보 일부 저장
        const tutorArr = res.data.map(tutor => ({
            tutorId: Number(tutor.tutorId),
            tutorName: tutor.tutorName,
            startDate: tutor.tutorContractInfo ? tutor.tutorContractInfo.startDate : null,
            endDate: tutor.tutorContractInfo ? tutor.tutorContractInfo.endDate : null,
        }))

        let hasUnregistered = false

        if (res.data.length > 0) {
            // 매칭된 강사명 리스트
            const matchedTutorNamesArray = res.data.map(tutor => tutor.tutorName)

            data.forEach(entry => {
                const instructorRole = ['주강사', '보조강사']
                instructorRole.forEach(key => {
                    const tutorData = entry[key]?.trim()
                    if (tutorData) {
                        const tutorList = tutorData.split(',').map(name => name.trim())
                        entry[key] = tutorList.map(name => {
                            const result = matchedTutorNamesArray.includes(removeParentheses(name))
                                ? name
                                : { name, unregistered: true }

                            if (result.unregistered) hasUnregistered = true
                            return result
                        })
                    } else {
                        entry[key] = null // 불필요한 세미콜론 방지
                    }
                })
            })
        } else {
            // 강사가 아예 등록되지 않은 경우 -> 모든 강사명 unregistered 처리
            data.forEach(entry => {
                const instructorRole = ['주강사', '보조강사']
                instructorRole.forEach(key => {
                    const tutorData = entry[key]?.trim()
                    if (tutorData) {
                        const tutorList = tutorData.split(',').map(name => name.trim())
                        entry[key] = tutorList.map(name => {
                            hasUnregistered = true
                            return { name, unregistered: true }
                        })
                    }
                })
            })
        }

        setDataState(prevState => ({
            ...prevState,
            jsonData: data,
            modifiedData: transformedData,
            isUploaded: true,
            fileName: file.name,
            hasDuplicateTutorName: null, // 강사관리 등록 시 중복 강사명 여부
            unRegisteredTutorName: hasUnregistered, // 교육관리 등록 시 주강사, 보조강사 등록 여부
            matchingTutorInfo: tutorArr, // 교육관리 등록 시 매칭되는 강사정보
        }))
    }

    /**
     * 강사 등록 시 강사명 validation
     * @param data
     * @param transformedData
     * @param file
     * @returns {Promise<void>}
     */
    const tutorValidateRegister = async (data, transformedData, file) => {
        // 현재 업로드한 엑셀에서 강사명 동명이인 확인
        const duplicateInfo = findDuplicateNames(transformedData)

        // tutor_name만 모아서 배열로 만들기
        const tutorNamesArray = transformedData.map(tutor => tutor.tutor_name)
        const tutorYearArray = transformedData.map(tutor => tutor.registration_year)

        // DB에서 같은 지역 강사 동명이인 찾기
        const res = await findDuplicatedTutorNames(tutorNamesArray, userInfo.regionId, tutorYearArray)
        if (res.data && res.data.length > 0) {
            // DB에서 같은 지역 강사 동명이인 배열
            const duplicatedNames = getDuplicateNameArr(res.data)

            //동영이인 강사가 있는 경우 duplicated값을 true로 표시하여 기존 배열에 추가
            const updatedData = data.map((tutor, index) => {
                const isDuplicated = duplicatedNames.includes(tutor['성명*'])
                let isInfoDuplicated = false // duplicateInfo가 null인 경우 기본값 설정
                if (duplicateInfo) {
                    isInfoDuplicated = duplicateInfo.some(({ indexes }) => indexes.includes(index))
                }
                //     //duplicated가 true인 경우 강사명 빨간색으로 노출
                return {
                    ...tutor,
                    duplicated: isDuplicated || isInfoDuplicated,
                }
            })

            setDataState(prevState => ({
                ...prevState,
                jsonData: updatedData,
                modifiedData: transformedData,
                isUploaded: true,
                fileName: file.name,
                hasDuplicateTutorName: true, // 강사관리 등록 시
                unRegisteredTutorName: null, // 교육관리 등록 시 주강사, 보조강사 등록 여부
                matchingTutorInfo: null, // 교육관리 등록시 강사매칭정보
            }))
        } else {
            setDataState(prevState => ({
                ...prevState,
                jsonData: data,
                modifiedData: transformedData,
                isUploaded: true,
                fileName: file.name,
                hasDuplicateTutorName: false, // 강사관리 등록 시 중복 강사명 여부
                unRegisteredTutorName: null, // 교육관리 등록 시 주강사, 보조강사 등록 여부
                matchingTutorInfo: null, // 교육관리 등록시 강사매칭정보
            }))
        }
    }

    /**
     * 재고관리 등록 시 자산번호 중복 확인
     * @param data
     * @param transformedData
     * @param file
     * @returns {Promise<void>}
     */
    const validateToolsNum = async (data, transformedData, file) => {
        // data 내에서 중복된 자산번호 확인
        const localDuplicateInfo = findDuplicateToolsNum(transformedData)

        // transformedData 내에서 중복된 자산번호 확인
        const duplicateInfo = findDuplicateToolsNum(transformedData)
        const toolsNumArray = transformedData.map(tools => String(tools.tools_num))
        const res = await findDuplicatedToolsNum(toolsNumArray, userInfo.regionId)

        // 중복된 자산번호가 있는 경우 duplicated값을 true로 표시하여 기존 배열에 추가
        const updatedData = data.map((tools, index) => {
            let isDuplicated = false
            if (res.data && res.data.length > 0) {
                const duplicatedToolsNum = getDuplicateToolsNum(res.data)
                isDuplicated = duplicatedToolsNum.includes(String(tools['자산번호*']))
            }

            let isInfoDuplicated = false
            if (duplicateInfo) {
                isInfoDuplicated = duplicateInfo.some(({ indexes }) => indexes.includes(index))
            }

            let isLocalDuplicated = false
            if (localDuplicateInfo) {
                isLocalDuplicated = localDuplicateInfo.some(({ indexes }) => indexes.includes(index))
            }

            return {
                ...tools,
                duplicated: isDuplicated || isInfoDuplicated || isLocalDuplicated,
            }
        })
        setDataState(prevState => ({
            ...prevState,
            jsonData: updatedData,
            modifiedData: transformedData,
            isUploaded: true,
            fileName: file.name,
            isDuplicateToolsNum: true,
        }))
    }

    const validateInventoryRegister = async (data, transformedData, file) => {
        // 필수값 체크
        await validateRequiredNull(data)

        // 자산번호 중복 체크
        await validateToolsNum(data, transformedData, file)
    }

    /**
     * 대여 반납 등록 시 validation
     * @param data
     * @returns {{isInvalidDate: boolean, isValidRentalStatus: boolean, hasRequiredNull: boolean}}
     */
    const validateRentalInputData = async (data, transformedData) => {
        let hasRequiredNull = false
        let isInvalidDate = false
        let isValidRentalStatus = false

        // 1) 1차 validation
        data.forEach(item => {
            Object.keys(item).forEach(key => {
                const isRequired = key.endsWith('*')
                const isEmpty = !item[key]

                // 필수 값이 비어 있는지 확인
                if (isRequired && isEmpty) {
                    console.log(`필수 값이 비어 있음: 키 = ${key}, 값 = ${item[key]}`)
                    hasRequiredNull = true
                }

                // 날짜 검증
                if (key === '대여 신청일자*') {
                    // 항상 체크
                    if (!isValidDateFormat(item[key])) {
                        console.log(`잘못된 날짜 형식: 키 = ${key}, 값 = ${item[key]}`)
                        isInvalidDate = true
                    }
                } else if (key === '대여 반출일자' || key === '대여 반납일자') {
                    // 빈 문자열이 아닐 때만 체크
                    if (item[key] !== '' && !isValidDateFormat(item[key])) {
                        console.log(`잘못된 날짜 형식: 키 = ${key}, 값 = ${item[key]}`)
                        isInvalidDate = true
                    }
                }

                if (key === '대여상태*') {
                    const validStatuses = ['대여신청', '신청취소', '반납완료', '대여확정', '대여불가능']
                    if (!validStatuses.includes(item[key])) {
                        isValidRentalStatus = true
                    }
                }

                // 하나라도 에러가 있으면 반복 종료
                return hasRequiredNull || isInvalidDate || isValidRentalStatus
            })
        })

        // 2) DB 검증용 배열 인자값 parsing
        const tutorNameArray = []
        const toolsNameArray = []
        const toolsNumArray = []

        data.forEach(item => {
            if (item['성명 (대여자)*']) {
                tutorNameArray.push(item['성명 (대여자)*'])
            }
            if (item['품목 (교구명)*']) {
                toolsNameArray.push(item['품목 (교구명)*'])
            }
            if (item['자산번호(제품번호)*']) {
                toolsNumArray.push(item['자산번호(제품번호)*'])
            }
        })

        // 3) RPC 호출
        let foundToolsData = []
        let foundTutorData = []
        try {
            // 3.1) 교구재 검색
            const { data: toolsData, error: toolsError } = await supabase.rpc('find_tools_inventory_indexed', {
                region_id_param: regionId,
                tools_name_param: toolsNameArray,
                tools_num_param: toolsNumArray,
            })

            if (toolsError) {
                throw new Error(toolsError.message)
            }
            // console.log('교구재 RPC 결과:', toolsData)
            foundToolsData = Array.isArray(toolsData) ? toolsData : []

            // 3.2) 대여자(교사) 검색
            const { data: tutorData, error: tutorError } = await supabase.rpc('find_tutor_ids_indexed', {
                region_id_param: regionId,
                tutor_name_param: tutorNameArray,
            })

            if (tutorError) {
                throw new Error(tutorError.message)
            }
            // console.log('대여자 RPC 결과:', tutorData)
            foundTutorData = Array.isArray(tutorData) ? tutorData : []
        } catch (err) {
            // console.log('[validateRentalInputData] DB 검증 에러', err)
            errorMessage(err.message) // 알림창 노출
        }

        // 4) DB 결과를 data에 merge
        //    각 row마다 'toolsMatched', 'tutorMatched' 필드 추가
        const mergedData = data.map((row, idx) => {
            const toolsRow = foundToolsData.find(r => r.index === idx)
            const tutorRow = foundTutorData.find(r => r.index === idx)
            const toolsId = toolsRow?.tools_id ?? null
            const tutorId = tutorRow?.tutor_id ?? null

            // 매칭 여부
            const toolsMatched = toolsId !== null
            const tutorMatched = tutorId !== null

            // 매칭된 교구, 강사 ID 추가
            const tools_id = toolsRow ? toolsRow.tools_id : null
            const tutor_id = tutorRow ? tutorRow.tutor_id : null

            return {
                ...row,
                toolsMatched,
                tutorMatched,
                tools_id,
                tutor_id,
            }
        })

        // 5) DB 반영할 데이터 생성
        const requestData = transformedData.map((row, idx) => {
            const toolsRow = foundToolsData.find(r => r.index === idx)
            const tutorRow = foundTutorData.find(r => r.index === idx)

            // 매칭된 교구, 강사 ID 추가
            const tools_id = toolsRow ? toolsRow.tools_id : null
            const tutor_id = tutorRow ? tutorRow.tutor_id : null

            return {
                ...row,
                tools_id,
                tutor_id,
            }
        })

        // 6) 하나라도 교구/교사 매칭이 안 된 레코드가 있는지 확인 (알림창 노출용)
        const isAnyToolUnmatched = mergedData.some(r => r.toolsMatched === false)
        const isAnyTutorUnmatched = mergedData.some(r => r.tutorMatched === false)

        return {
            hasRequiredNull,
            isInvalidDate,
            isValidRentalStatus,
            isAnyToolUnmatched,
            isAnyTutorUnmatched,
            mergedData,
            requestData,
        }
    }

    const validateRentalRegister = async (data, transformedData, file) => {
        const {
            hasRequiredNull, // 필수값
            isInvalidDate, // 날짜 포맷
            isValidRentalStatus, // 대여 상태 포먓
            isAnyToolUnmatched, // 교구재 매칭여부
            isAnyTutorUnmatched, // 대여자 매칭여부
            mergedData, // 매칭 여부 합친 데이터
            requestData, // 매칭된 강사, 교구 ID 추가한 데이터
        } = await validateRentalInputData(data, transformedData)

        // 오류 메시지를 담을 배열
        const alertMessages = []

        if (hasRequiredNull) {
            alertMessages.push(requiredNullError)
        }
        if (isInvalidDate) {
            alertMessages.push(isInvalidDateError)
        }
        if (isValidRentalStatus) {
            alertMessages.push(isValidRentalStatusError)
        }
        // 교구재 매칭 여부 확인
        if (isAnyToolUnmatched) {
            alertMessages.push(isAnyToolUnmatchedError)
        }
        // 대여자(강사) 매칭 여부 확인
        if (isAnyTutorUnmatched) {
            alertMessages.push(isAnyTutorUnmatchedError)
        }
        // 하나라도 메시지가 있으면 경고창 노출
        if (alertMessages.length > 0) {
            const combinedMessage = alertMessages.join('\n')
            errorMessage(<div style={{ whiteSpace: 'pre-wrap' }}>{combinedMessage}</div>) // 개행 처리
        }

        setDataState(prevState => ({
            ...prevState,
            jsonData: mergedData,
            modifiedData: requestData,
            isUploaded: true,
            fileName: file.name,
            requiredNull: hasRequiredNull,
            isInvalidDate,
            isValidRentalStatus,
            isAnyToolUnmatched,
            isAnyTutorUnmatched,
        }))
    }

    /**
     * 1. 첫 파일 업로드 시 처리
     * @param file
     */
    const handleFileUpload = async file => {
        const reader = new FileReader()
        reader.onload = async event => {
            const workbook = XLSX.read(event.target.result, { type: 'binary' })
            const sheetName = workbook.SheetNames[0]
            const sheet = workbook.Sheets[sheetName]

            // range 지정 (첫번째 cell 제거 후 import)
            const range = XLSX.utils.decode_range(sheet['!ref'])
            range.s.r = 1 // <-- zero-indexed, so setting to 1 will skip row 0
            sheet['!ref'] = XLSX.utils.encode_range(range)

            // 엑셀 -> json 변환 (각각의 index값 추가)
            const data = XLSX.utils.sheet_to_json(sheet, { blankrows: false, defval: '' })

            // convert json
            const transformedData = handleConvertjsonData(data)

            if (isTutorURL) {
                await tutorValidateRegister(data, transformedData, file)
            }

            if (isProgramURL) {
                await validateLeadInstructorAndAssistant(data, transformedData, file)
            }

            if (isInventoryURL) {
                await validateInventoryRegister(data, transformedData, file)
            }
            if (isRentalURL) {
                await validateRentalRegister(data, transformedData, file)
            }
        }
        reader.readAsBinaryString(file)
    }

    const handleDeleteFile = () => {
        setDataState(prevState => ({
            ...prevState,
            jsonData: [],
            isUploaded: false,
            fileName: '',
            hasDuplicateTutorName: null, // 강사관리 등록 시 중복 강사명 여부
            unRegisteredTutorName: null, // 교육관리 등록 시 주강사, 보조강사 등록 여부
            matchingTutorInfo: null, // 교육관리 등록시 강사매칭정보
            requiredNull: null, // 필수값 누락 여부
            isDuplicateToolsNum: null, // 재고관리 등록 시 자산번호 중복 여부
        }))
    }

    const handleRegisterTutorAPI = async () => {
        if (fileName === '') {
            setAlertOpen(true)
        } else {
            try {
                const response = await postTutors(modifiedData)
                if (response.status === 201) {
                    setModalOpen(true)
                } else if (response.code === 'ERR_BAD_REQUEST') {
                    errorMessage(registerError)
                } else {
                    errorMessage(registerError)
                }
                // TODO: 로그인 세션 분기처리 필요
                // else if (data.error.message === '로그인 해 주세요.') {
                //     loginErrorMessage()
                //     navigate('/')
                // }
            } catch (error) {
                console.error('강사 목록을 불러오는 동안 오류가 발생했습니다.', error)
                return null
            }
        }
        return false
    }

    /**
     * 괄호 (교시 정보)에 대한 공통 처리 함수
     * @param tutorWithHour
     * @returns {{tutor_name: *, class_hour: (number|null)}|{tutor_name: *, class_hour: null}}
     */
    const parseTutorWithHour = tutorWithHour => {
        // 괄호값 (교시 정보) 있는지 확인
        const result = tutorWithHour.match(/([^(]+)(?:\((\d+)\))?/)
        if (result) {
            const [, tutor, classHour] = result
            return {
                tutor_name: tutor.trim(),
                class_hours: classHour ? parseInt(classHour, 10) : null,
            }
        }
        return {
            tutor_name: tutorWithHour.trim(),
            class_hours: null,
        }
    }

    /**
     * 강사 타입, 교시 매칭
     * 예시 : {tutor_type: 'lead_Instructors', program_id: 198, tutor_name: '홍길동', class_hour: null}
     * @param inputArray
     * @returns {*}
     */
    const matchingTutorTypeAndClassHour = inputArray => {
        return inputArray.flatMap(item => {
            const leadInstructorsArray = item.lead_Instructors
                ? item.lead_Instructors.split(',').map(entry => entry.trim())
                : []
            const assistantsArray = item.assistants ? item.assistants.split(',').map(entry => entry.trim()) : []
            const instructors = leadInstructorsArray.map(tutorWithHour => ({
                tutor_type: '주강사',

                program_id: item.program_id,
                ...parseTutorWithHour(tutorWithHour),
            }))

            const assistants = assistantsArray.map(tutorWithHour => ({
                tutor_type: '보조강사',
                program_id: item.program_id,
                ...parseTutorWithHour(tutorWithHour),
            }))

            return [...instructors, ...assistants]
        })
    }

    const handleRegisterProgramAPI = async () => {
        if (fileName === '') {
            setAlertOpen(true)
        } else {
            const requiredFields = [
                'program_name',
                'detail',
                'education_type',
                'course',
                'method',
                'category',
                'grade',
                'organization_name',
                'address_sido',
                'address_sigungu',
                'address_bname',
                'number_of_applicants',
                'start_date',
                'end_date',
                'class_hours',
                'number_of_session',
                'registration_year',
            ]
            const missingFields = Object.values(modifiedData).some(item =>
                requiredFields.some(
                    field => !item[field] || (typeof item[field] === 'string' && item[field].trim() === ''),
                ),
            )

            if (missingFields) {
                errorMessage('필수값이 누락되었습니다.')
                return
            }
            try {
                // 주강사, 보조강사 값을 제외한 새로운 객체 생성
                const newData = []
                const instructorsArray = [] // 배열로 관리할 배열

                Object.keys(modifiedData).forEach(key => {
                    const { lead_Instructors, assistants, ...newItem } = modifiedData[key]
                    newData[key] = newItem

                    // lead_Instructors와 assistants를 같은 배열로 관리
                    if (lead_Instructors) {
                        instructorsArray.push(...lead_Instructors.split(',').map(item => item.trim()))
                    }

                    if (assistants) {
                        instructorsArray.push(...assistants.split(',').map(item => item.trim()))
                    }
                })

                // 데이터 중 주강사와, 보조강사 데이터를 분리한 배열

                const newArray = Object.values(modifiedData).map(item => {
                    const lead_Instructors = item.lead_Instructors
                        ? item.lead_Instructors
                              .split(',')
                              .map(entry => entry.trim())
                              .join(', ')
                        : null
                    const assistantsArray = item.assistants
                        ? item.assistants
                              .split(',')
                              .map(entry => entry.trim())
                              .join(', ')
                        : null

                    return {
                        lead_Instructors,
                        assistants: assistantsArray,
                    }
                })
                // 대량 등록 시 registration_year 컬럼에 등록 년도 추가
                newData?.forEach(item => {
                    item.registration_year = dayjs().year()
                })

                // 교육 먼저 등록
                const response = await postProgram(newData)

                if (response && response.status === 201) {
                    const { data } = response
                    if (data && data.length > 0) {
                        const allNull = newArray.every(
                            item => item.lead_Instructors === null && item.assistants === null,
                        )

                        // 주강사 보조강사 정보가 존재할 때
                        if (allNull === false) {
                            // 방금 등록한 프로그램의 이름과 id값 배열 저장
                            const registeredProgramInfo = data.map(item => ({
                                program_name: item.program_name,
                                program_id: item.program_id,
                            }))

                            // 주강사와 보조강사 정보에 프로그램 ID값 매칭
                            const combinedArray = newArray.map((item, index) => ({
                                lead_Instructors: item.lead_Instructors ? item.lead_Instructors : null,
                                assistants: item.assistants ? item.assistants : null,
                                program_id: registeredProgramInfo[index].program_id,
                            }))

                            // 강사타입과 교시 데이터 추가 매칭한 값
                            const matchingResult = matchingTutorTypeAndClassHour(combinedArray)

                            // 각 튜터 정보에 프로그램 ID를 추가하여 정확하게 매칭
                            const mergedArray = matchingResult.map(matchingItem => {
                                // 같은 이름을 가진 모든 튜터 매칭
                                const matchingTutor = matchingTutorInfo.find(
                                    resultItem => resultItem.tutorName === matchingItem.tutor_name,
                                )

                                if (matchingTutor) {
                                    return {
                                        ...matchingItem,
                                        tutor_id: matchingTutor.tutorId,
                                        start_date: matchingTutor.startDate,
                                        end_date: matchingTutor.endDate,
                                        is_delete: false,
                                    }
                                }
                                return null
                            })

                            const filteredArray = mergedArray.filter(item => item !== null)

                            try {
                                const programInstructorMatchingResult =
                                    await postProgramInstructorMatching(filteredArray)
                                console.log('programInstructorMatchingResult:', programInstructorMatchingResult)
                            } catch (error) {
                                console.error('Error in postProgramInstructorMatching:', error)
                            }
                        }
                    }
                    setModalOpen(true)
                } else if (response.code === 'ERR_BAD_REQUEST') {
                    errorMessage(registerError)
                } else {
                    errorMessage(registerError)
                }
            } catch (error) {
                console.error('프로그램 목록을 불러오는 동안 오류가 발생했습니다.', error)
            }
        }
    }

    const handleRegisterInventoryAPI = async () => {
        if (fileName === '') {
            setAlertOpen(true)
            return false
        }
        try {
            const response = await insertToolsInventory(modifiedData, regionId)
            if (response && response.status === 201) {
                setModalOpen(true)
                return true
            }
            errorMessage(registerError)
            return false
        } catch (error) {
            console.error('재고 목록을 불러오는 동안 오류가 발생했습니다.', error)
            return false
        }
    }

    const handleRegisterRentalAPI = async () => {
        if (fileName === '') {
            setAlertOpen(true)
        } else {
            try {
                const { status } = await supabase.rpc('rpc_insert_tools_rental', {
                    _data: modifiedData,
                })
                if (status && (status === 204 || status === 201)) {
                    setModalOpen(true)
                } else {
                    errorMessage(registerError)
                }
            } catch (error) {
                console.error('재고 목록을 불러오는 동안 오류가 발생했습니다.', error)
                errorMessage(registerError)
            }
        }
    }

    /**
     * URL에 따라 분기처리
     */
    const handleConfirmRegisterURLPath = () => {
        if (isTutorURL) {
            if (hasDuplicateTutorName) {
                errorMessage(duplicatedError)
            } else {
                handleRegisterTutorAPI()
            }
        } else if (isProgramURL) {
            if (unRegisteredTutorName) {
                errorMessage(unregisterError)
            } else {
                handleRegisterProgramAPI()
            }
        } else if (isInventoryURL) {
            if (requiredNull) {
                errorMessage(requiredNullError)
            } else if (isDuplicateToolsNum) {
                errorMessage(duplicatedToolsNumError)
            } else {
                handleRegisterInventoryAPI()
            }
        } else if (isRentalURL) {
            const errorList = []
            if (requiredNull) {
                errorMessage(requiredNullError)
                return
            }
            if (isInvalidDate) {
                errorList.push(isInvalidDateError)
            }
            if (isValidRentalStatus) {
                errorList.push(isValidRentalStatusError)
            }
            if (isAnyToolUnmatched) {
                errorList.push(isAnyToolUnmatchedError)
            }
            if (isAnyTutorUnmatched) {
                errorList.push(isAnyTutorUnmatchedError)
            }
            if (errorList.length > 0) {
                const finalErrorMessage = errorList.map((msg, idx) => (
                    <div key={uuidv4()}>
                        {msg}
                        {/* 마지막 메시지에는 <br/> 삽입 안 하기 위해 조건 분기 */}
                        {idx < errorList.length - 1 && <br />}
                    </div>
                ))
                errorMessage(finalErrorMessage)
            } else {
                handleRegisterRentalAPI()
            }
        }
    }

    const getTitle = pathName => {
        const pathMap = {
            program: `프로그램`,
            tutors: `강사`,
            inventory: `재고 관리`,
            rental: `대여관리`,
            // statistics: ``,
            // report: ``,
            // survey: ``,
            // accounts: ``,
        }

        return pathMap[pathName] || ''
    }

    return (
        <div className={styles.content_area}>
            <div className={styles.register_area}>
                <span className="h4">
                    {paths[0] === 'tools' ? (paths[1] === 'inventory' ? '재고' : '대여반납') : getTitle(paths[0])} 정보
                    등록
                </span>
                <div className={styles.required_input_info}>
                    <div className="body2">
                        {paths[0] === 'tools' ? (paths[1] === 'inventory' ? '재고' : '대여반납') : getTitle(paths[0])}{' '}
                        정보는 필수입력사항입니다.
                    </div>
                    <div className="body2">
                        [양식다운로드] 버튼을 클릭하신 후 셀양식을 받아서 이용하시면 편리하게 사용할 수 있으며 항목
                        변경에 따른 실수를 방지할 수 있습니다.
                    </div>
                </div>
                <ColorButton
                    override={blue['900']}
                    icon={<DownloadIcon24 />}
                    size="download"
                    onClick={() => handleDownloadForm()}
                >
                    양식 다운로드
                </ColorButton>
            </div>
            <div className={styles.upload_area_wrap}>
                <DraggableUpload
                    name="file"
                    accept=".xlsx, .xls"
                    handleFileUpload={handleFileUpload}
                    list={jsonData}
                    fileName={fileName}
                    handleDeleteFile={handleDeleteFile}
                />
            </div>
            {jsonData.length > 0 && <ListView list={jsonData} />}
            <Flex justify="center" align="center" gap={8}>
                <ColorButton size="large" type="primary" onClick={() => handleConfirmRegisterURLPath()}>
                    등록
                </ColorButton>
                <Link
                    to={
                        isProgramURL
                            ? '/program'
                            : isTutorURL
                            ? '/tutors'
                            : isInventoryURL
                            ? '/tools/inventory'
                            : isRentalURL
                            ? '/tools/rental'
                            : ''
                    }
                >
                    <ColorButton size="large" override={blue['900']}>
                        취소
                    </ColorButton>
                </Link>
            </Flex>
        </div>
    )
}

export default Register
