import { Col, Row, Form } from 'antd'
import SelectBox from '../../Select'
import TextField from '../../TextField'

import styles from '../input.module.scss'

/**
 *
 * @param label
 * @param options
 * @param handleChange
 * @param name
 * @param rules
 * @param value
 * @param noStyle 하단 validation erorr 제거 및 스타일 제거
 * @param password
 * @param disabled
 * @param leftCol
 * @param rightCol
 * @returns {JSX.Element}
 * @constructor
 */
const InputSection = ({
    label,
    options,
    handleChange,
    handleBlur,
    name,
    rules,
    value,
    noStyle,
    password,
    disabled,
    leftCol = 7,
    rightCol = 17,
}) => {
    return (
        <div className={styles.passwordInput}>
            <Row>
                <Col span={leftCol}>
                    <span>{label}</span>
                </Col>
                <Col span={rightCol}>
                    <Form.Item name={name} rules={rules} noStyle={!!noStyle} style={{ minHeight: '50px' }}>
                        {options ? (
                            <SelectBox
                                onChange={handleChange}
                                options={options}
                                size="lg"
                                value={value}
                                defaultValue={{ value: '', label: '지역을 선택해주세요' }}
                            />
                        ) : (
                            <TextField val={value || ''} password={password} disabled={disabled} onBlur={handleBlur} />
                        )}
                    </Form.Item>
                </Col>
            </Row>
        </div>
    )
}

export default InputSection
